import palette from 'styles/theme/palette';

export default function RootNodeIcon() {
  return (
    <svg fill="none" viewBox="0 0 134 68" xmlns="http://www.w3.org/2000/svg">
      <rect width="134" height="64" rx="9" fill="currentColor" />
      <rect
        x="4"
        y="4"
        width="126"
        height="56"
        rx="6"
        fill={palette.primary.main}
        stroke={palette.common.white}
        strokeWidth="4"
      />
      <rect
        x="14.7679"
        y="13.2174"
        width="96.0491"
        height="7.04348"
        rx="3.52174"
        fill={palette.common.white}
      />
      <rect
        x="14.7679"
        y="25.2174"
        width="57.7455"
        height="7.04348"
        rx="3.52174"
        fill={palette.common.white}
      />
      <circle cx="67" cy="63" r="5" fill={palette.common.white} />
      <circle
        cx="67"
        cy="63"
        r="2"
        fill={palette.common.white}
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}
