import type { RoadmapData } from './RoadmapData';
import type { EditDrawerState } from './EditDrawerState';
import type { DetailsDrawerState } from './DetailsDrawerState';
import { NodeFilters } from './NodeData';

export enum SavingStatus {
  IDLE,
  LOADING,
  SUCCESS,
  ERROR,
}

export interface RoadmapState {
  data: RoadmapData;
  loading: boolean;
  isNewNodeDrawerOpen: boolean;
  saving: { time: number; status: SavingStatus };
  editDrawer: EditDrawerState;
  detailsDrawer: DetailsDrawerState;
  filters: {
    isDrawerOpen: boolean;
    data: NodeFilters;
  };
}
