import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ScorecardChangeMultiplierDto,
  Scorecard,
  ScorecardsActionStoreArgument,
} from 'store/interfaces';
import api from 'api';
import { API_SCORECARD } from 'constants/api';
import { GoalField } from 'components/GoalsAndSubgoalsForm/interfaces';
import { updateFullData } from './updateFullData';

export const patchScorecard = createAsyncThunk<
  Scorecard,
  {
    id: number;
    data: ScorecardChangeMultiplierDto | GoalField;
    store: ScorecardsActionStoreArgument;
  }
>(`scorecards/patchScorecard`, async ({ id, data, store }, thunkAPI) =>
  api.axios
    .patch<Scorecard>(`${API_SCORECARD}/${id}`, data)
    .then((res) => {
      thunkAPI.dispatch(updateFullData({ id, store }));
      return res.data;
    })
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
