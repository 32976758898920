import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.myTeam.title',
    defaultMessage: 'My Team',
  },
  directReports: {
    id: 'pages.myTeam.directReports',
    defaultMessage: 'Direct Reports',
  },
  subordinates: {
    id: 'pages.myTeam.subordinates',
    defaultMessage: 'Subordinates',
  },
  extraMultiplierTooltip07: {
    id: 'pages.myTeam.extraMultiplierTooltip07',
    defaultMessage: 'External escalation(s)',
  },
  extraMultiplierTooltip09: {
    id: 'pages.myTeam.extraMultiplierTooltip09',
    defaultMessage: 'Internal escalation(s)',
  },
  extraMultiplierTooltip11: {
    id: 'pages.myTeam.extraMultiplierTooltip11',
    defaultMessage: 'Positive feedback from Unit/Department Managers level',
  },
  extraMultiplierTooltip13: {
    id: 'pages.myTeam.extraMultiplierTooltip13',
    defaultMessage: 'Positive feedback from the customer',
  },
});
