import { useSelector } from 'react-redux';
import { selectProfileInfo } from 'store/profile';
import appRoutes from 'components/AppRoutes/routes';
import { ROLE_EMPLOYEE } from 'constants/roles';
import { useMemo } from 'react';
import { AppRouteItem } from 'components/AppRoutes';

function filterRoutes(
  items: AppRouteItem[],
  roles: string[],
  userEmail: string,
) {
  if (!roles.includes(ROLE_EMPLOYEE)) {
    return [];
  }

  const res: AppRouteItem[] = [];
  items.forEach((item) => {
    const { roles: requiredRoles = [], routes = [], emails = [] } = item;

    if (emails.length && !emails.includes(userEmail)) {
      return;
    }

    const isEnabled = requiredRoles.some((requiredRole) =>
      roles.includes(requiredRole),
    );

    if (isEnabled) {
      res.push({ ...item, routes: filterRoutes(routes, roles, userEmail) });
    }
  });

  return res;
}

export default function useAccessibleRoutes() {
  const { roles, email } = useSelector(selectProfileInfo);
  return useMemo<AppRouteItem[]>(
    () => filterRoutes(appRoutes, roles, email),
    [roles, email],
  );
}
