import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_LOGIN_UNIVERSAL } from 'constants/api';
import { showToast } from 'store/toast';
import { setIsLoggedIn } from 'helpers';
import { storeName } from '../config';
import { ProfileRefreshTokensResponse } from '../interfaces';

export const profileUniversalLogIn = createAsyncThunk<
  ProfileRefreshTokensResponse,
  { username: string; password: string }
>(`${storeName}/devLogin`, async (data, thunkAPI) =>
  api.axios
    .post(API_LOGIN_UNIVERSAL, data)
    .then((res) => {
      setIsLoggedIn(true);
      return res.data;
    })
    .catch((e) => {
      thunkAPI.dispatch(
        showToast({
          severity: 'error',
          message: e.response?.data.message || e.message,
        }),
      );
      return thunkAPI.rejectWithValue(e.response?.data);
    }),
);
