import { memo } from 'react';
import type { NodeProps } from 'reactflow';
import { Typography } from '@mui/material';
import type { NodeData } from 'store/roadmap';
import { isEqual } from 'lodash-es';
import { NodeWrapper, RootNodeInner } from './NodeWrapper';
import NodeMenu from './NodeMenu.component';
import Handlers from './Handlers';

function RootNode({
  data,
  sourcePosition,
  targetPosition,
  isConnectable,
}: NodeProps<NodeData>) {
  return (
    <>
      <NodeWrapper>
        <RootNodeInner>
          <Typography variant="h5">{data.title}</Typography>
        </RootNodeInner>

        {isConnectable && <NodeMenu />}
      </NodeWrapper>

      <Handlers
        edges={data.edges}
        sourcePosition={sourcePosition}
        targetPosition={targetPosition}
        isConnectable={isConnectable}
      />
    </>
  );
}

RootNode.displayName = 'RootNode';

export default memo(
  RootNode,
  (prev, next) =>
    isEqual(prev.data, next.data) &&
    prev.targetPosition === next.targetPosition &&
    prev.sourcePosition === next.sourcePosition,
);
