import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { API_LOGIN_REFRESH, API_LOGIN_UNIVERSAL } from 'constants/api';
import { ProfileRefreshTokensResponse } from 'store/profile';

export default function addUnauthorizedInterceptor(
  axiosInstance: AxiosInstance,
  setProfile: (profile: ProfileRefreshTokensResponse) => void,
  logout: () => void,
) {
  axiosInstance.interceptors.response.use(
    (res: AxiosResponse) => res,
    async (err: AxiosError) => {
      const originalConfig = err.config as AxiosRequestConfig & {
        retry: boolean;
      };

      if (err.response) {
        if (
          err.response.status === 401 &&
          !originalConfig.retry &&
          [API_LOGIN_REFRESH, API_LOGIN_UNIVERSAL].every(
            (url) => !originalConfig.url?.startsWith(url),
          )
        ) {
          originalConfig.retry = true;

          try {
            const { data } = await axiosInstance.post(API_LOGIN_REFRESH);
            setProfile(data);
            return await axiosInstance(originalConfig);
          } catch (error) {
            return Promise.reject(error);
          }
        }

        if (
          err.response.status === 401 &&
          originalConfig.url?.startsWith(API_LOGIN_REFRESH)
        ) {
          logout();
        }
      }

      return Promise.reject(err);
    },
  );
}
