import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Pagination,
  Scorecard,
  ScorecardsActionStoreArgument,
  ScorecardStores,
} from 'store/interfaces';
import api from 'api';
import { API_SCORECARD_OWN, API_SCORECARD_SEARCH } from 'constants/api';
import qs from 'query-string';
import { RootState } from 'store/index';
import { storeName as subordinateStoreName } from 'store/scorecards/subordinates/config';
import { storeName as directReportsStoreName } from 'store/scorecards/direct/config';
import { storeName as ownScorecardsStoreName } from 'store/scorecards/own/config';

function getAdditionalParameters(storeName: ScorecardsActionStoreArgument) {
  switch (storeName) {
    case subordinateStoreName:
      return { indirectSubordinates: 1 };
    case directReportsStoreName:
      return { subordinates: 1 };
    default:
      return {};
  }
}
export const updateList = createAsyncThunk<
  {
    list: Scorecard[];
    pagination: Pagination;
  },
  {
    store: ScorecardStores;
  }
>('scorecards/updateList', async ({ store }, thunkAPI) => {
  const {
    [store]: {
      filters,
      pagination: { page },
    },
  } = thunkAPI.getState() as RootState;
  const url =
    store === ownScorecardsStoreName ? API_SCORECARD_OWN : API_SCORECARD_SEARCH;
  return api.axios
    .get(
      `${url}?${qs.stringify(
        {
          ...getAdditionalParameters(store),
          ...filters,
          page,
        },
        { skipNull: true, skipEmptyString: true },
      )}`,
    )
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data));
});
