import { useForm } from 'react-hook-form';
import {
  FeedbackRequestFormValues,
  RequestGoalFormValues,
} from 'pages/FeedbackRequests/interfaces';
import { parseFeedbackRequestFormData } from 'helpers/feedbacks';
import { FeedbackRequestRO, TargetScorecardGoal } from 'store/interfaces';
import {
  CatchCallback,
  SuccessCallback,
  useActionPipeline,
  usePrompt,
  useToast,
  useResetAfterSubmit,
} from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { updateFeedback } from 'store/feedbackRequests';
import {
  FEEDBACK_REQUEST_GOAL_STATUS_PENDING,
  DEVIATING_SCORE_VALUES,
} from 'constants/feedbacks';
import { handleResponseErrors, isFormDirty } from 'helpers';
import { commonTexts } from 'i18n';
import { cloneDeep } from 'lodash-es';
import { AssessmentScaleGroupAlias } from 'store/interfaces/ScorecardExternalSubgoalRO';

export default function useRequestItemForm(
  data: FeedbackRequestRO,
  activeGoalIndex: number,
  goToNextPendingGoal: () => void,
  allowScorecardComment?: boolean,
) {
  const parsedFeedbackRequestFormData = useMemo(
    () => parseFeedbackRequestFormData(data),
    [data],
  );
  const form = useForm<FeedbackRequestFormValues>({
    defaultValues: parsedFeedbackRequestFormData,
    mode: 'onChange',
  });
  const { reset, getValues } = form;

  useEffect(() => {
    const formData: FeedbackRequestFormValues = cloneDeep(getValues());
    parsedFeedbackRequestFormData.goalHeaders.forEach((parsedHeader) => {
      const isNewHeader =
        formData.goalHeaders.findIndex(
          (formDataHeader) => formDataHeader.id === parsedHeader.id,
        ) === -1;

      if (isNewHeader) {
        formData.goalHeaders.push(parsedHeader);
      }
    });
    reset(formData);
  }, [reset, parsedFeedbackRequestFormData, getValues]);

  const { handleSubmit, setError, formState } = form;
  const [modalProps, setModalProps] = useState<{
    confirm: () => void;
    cancel: () => void;
  } | null>(null);
  const isDirty = isFormDirty(formState);
  const showMessage = useToast();
  const { formatMessage } = useIntl();
  const {
    requestGoals,
    targetScorecard: {
      workRecord: {
        user: { fullName },
      },
    },
  } = data;
  useResetAfterSubmit(form);
  usePrompt(formatMessage(commonTexts.unsavedChangesPrompt), isDirty);
  const { [activeGoalIndex]: activeGoal } = requestGoals;
  const isSubmit =
    requestGoals.filter(
      ({ status }) => status === FEEDBACK_REQUEST_GOAL_STATUS_PENDING,
    ).length < 2;
  const targetScorecardGoal = activeGoal
    ? (activeGoal.targetScorecardGoal as TargetScorecardGoal)
    : null;
  const activeGoalName = targetScorecardGoal?.name || '';

  const onSuccess = useCallback<SuccessCallback<FeedbackRequestRO>>(() => {
    showMessage({
      severity: 'success',
      message: formatMessage(
        {
          id: 'pages.feedbackRequests.feedbackSent',
          defaultMessage:
            'You successfully submitted feedback for {goalName} for {userName}',
        },
        {
          goalName: activeGoalName,
          userName: fullName,
        },
      ),
    });
    goToNextPendingGoal();
  }, [
    activeGoalName,
    formatMessage,
    fullName,
    goToNextPendingGoal,
    showMessage,
  ]);

  const onCatch = useMemo<CatchCallback>(
    () => handleResponseErrors<FeedbackRequestFormValues>(setError),
    [setError],
  );
  const actionPipeline = useActionPipeline(onSuccess, onCatch);
  const onSubmit = useCallback(
    (formData: FeedbackRequestFormValues) => {
      const payload: RequestGoalFormValues = {
        ...formData.goalHeaders[activeGoalIndex],
        comment: formData.goalHeaders[activeGoalIndex].comment
          ? formData.goalHeaders[activeGoalIndex].comment?.trim()
          : null,
      };

      const hasAtLeastOneStandardScaleSubgoal =
        targetScorecardGoal?.subgoals.some(
          (s) =>
            s.assessmentScaleGroup.alias === AssessmentScaleGroupAlias.STANDARD,
        );

      const hasOtherThatAsExpected = payload.subgoals.reduce(
        (acc, { assessmentScaleId }) => {
          if (DEVIATING_SCORE_VALUES.includes(assessmentScaleId as number)) {
            return true;
          }
          return acc;
        },
        false,
      );

      const save = () => {
        setModalProps(null);
        actionPipeline(
          updateFeedback({
            id: formData.id,
            submit: isSubmit,
            goalHeaders: [payload],
          }),
        );
      };
      if (
        hasOtherThatAsExpected &&
        !payload.comment &&
        hasAtLeastOneStandardScaleSubgoal &&
        (typeof allowScorecardComment === 'undefined' ||
          !!allowScorecardComment)
      ) {
        setModalProps({
          confirm: save,
          cancel: () => setModalProps(null),
        });
      } else {
        save();
      }
    },
    [
      actionPipeline,
      activeGoalIndex,
      allowScorecardComment,
      isSubmit,
      targetScorecardGoal?.subgoals,
    ],
  );
  const onSubmitClick = useMemo(
    () => handleSubmit(onSubmit),
    [handleSubmit, onSubmit],
  );
  return {
    modalProps,
    form,
    onSubmitClick,
  };
}
