import { UIOption } from 'store/options';
import { useMemo } from 'react';
import { uniq } from 'lodash-es';
import { useNodes } from 'reactflow';
import { NodeData } from 'store/roadmap';

export default function useFormAutocompleteOptions(
  fieldName: keyof NodeData,
): UIOption[] {
  const node = useNodes<NodeData>();

  return useMemo(() => {
    const uniqueValues = uniq(
      node
        .filter((item) => item.data?.[fieldName])
        .map(({ data }) => data[fieldName] as string),
    );

    return uniqueValues.map((value) => ({ id: value, name: value }));
  }, [node, fieldName]);
}
