import {
  FormGoal,
  FormSubGoal,
  GoalField,
} from 'components/GoalsAndSubgoalsForm/interfaces';
import {
  ScorecardGoal,
  ScorecardParam,
  ScorecardSubGoal,
} from 'store/interfaces';
import { FormValues } from 'components/ScorecardDetails/interfaces';
import {
  ALLOW_SCORECARD_OWNER_EDITS_PARAMETER_TYPE,
  CALCULATE_SELF_ASSESSMENT_PARAMETER_TYPE,
  ALLOW_SCORECARD_COMMENT_PARAMETER_TYPE,
  ENABLE_SELF_ASSESSMENT_PARAMETER_TYPE,
} from 'constants/scorecards';
import { isCuSatGoal } from './isCuSatGoal';

export enum SelfAssessmentSetups {
  request = 'request',
  calculate = 'calculate',
  disable = 'disable',
}

function parseSubgoals(items: ScorecardSubGoal[]): FormSubGoal[] {
  return items.map(
    ({ assessmentScaleGroup, id, name, description, orderRank, weight }) => ({
      id,
      name,
      description,
      orderRank,
      weight,
      assessmentScaleGroupId: assessmentScaleGroup?.id || '',
    }),
  );
}

export function parseParams(params: ScorecardParam[]) {
  const calculateSelfAssessmentParam = params?.find(
    (p) => p.type?.alias === CALCULATE_SELF_ASSESSMENT_PARAMETER_TYPE,
  );
  const allowScorecardOwnerEditsParam = params?.find(
    (p) => p.type?.alias === ALLOW_SCORECARD_OWNER_EDITS_PARAMETER_TYPE,
  );
  const allowScorecardCommentParam = params?.find(
    (p) => p.type?.alias === ALLOW_SCORECARD_COMMENT_PARAMETER_TYPE,
  );
  const enableSelfAssessmentParam = params?.find(
    (p) => p.type?.alias === ENABLE_SELF_ASSESSMENT_PARAMETER_TYPE,
  );

  let selfAssessmentSetup: SelfAssessmentSetups;

  if (calculateSelfAssessmentParam?.value) {
    selfAssessmentSetup = SelfAssessmentSetups.calculate;
  } else if (enableSelfAssessmentParam?.value) {
    selfAssessmentSetup = SelfAssessmentSetups.request;
  } else {
    selfAssessmentSetup = SelfAssessmentSetups.disable;
  }

  return {
    allowScorecardComment: allowScorecardCommentParam?.value,
    allowScorecardOwnerEdits: allowScorecardOwnerEditsParam?.value,
    calculateSelfAssessment: calculateSelfAssessmentParam?.value,
    enableSelfAssessment: enableSelfAssessmentParam?.value,
    selfAssessmentSetup,
  };
}

export function parseScorecardData(goals: ScorecardGoal[]): FormGoal[] {
  return goals.map(
    ({
      subgoals,
      type,
      id,
      name,
      description,
      weight,
      orderRank,
      isCustom,
      extraMultiplier,
      params,
    }) => {
      const {
        allowScorecardComment,
        allowScorecardOwnerEdits,
        selfAssessmentSetup,
      } = parseParams(params);
      return {
        id,
        name,
        description: description ?? undefined,
        weight,
        orderRank,
        isCustom,
        extraMultiplierId: extraMultiplier?.id || null,
        subgoals: parseSubgoals(subgoals),
        type,
        allowScorecardComment: allowScorecardComment ?? false,
        allowScorecardOwnerEdits: allowScorecardOwnerEdits ?? false,
        selfAssessmentSetup,
      };
    },
  );
}

export function formatScorecardData(
  { id: scorecardId, goals, totalWeight, entity, ...rest }: FormValues,
  withRestrictions: boolean = false,
  isActiveOwnScorecard: boolean = false,
): GoalField {
  return {
    ...rest,
    goals: goals.map((goal, goalIndex) => {
      const {
        weight: goalWeight,
        subgoals,
        name,
        id,
        description,
        allowScorecardOwnerEdits,
        allowScorecardComment,
        selfAssessmentSetup,
      } = goal;
      const goalFields = {
        weight: Number.parseInt(goalWeight as string, 10),
      };
      if (id) {
        Object.assign(goalFields, { id });
      }
      const sendAllFields =
        !withRestrictions || (isActiveOwnScorecard && allowScorecardOwnerEdits);
      const sendSubgoalWeightField =
        !isActiveOwnScorecard ||
        (isActiveOwnScorecard && allowScorecardOwnerEdits);

      if (isActiveOwnScorecard) {
        if (allowScorecardOwnerEdits) {
          Object.assign(goalFields, {
            params: [
              {
                alias: CALCULATE_SELF_ASSESSMENT_PARAMETER_TYPE,
                value: selfAssessmentSetup === SelfAssessmentSetups.calculate,
              },
              {
                alias: ALLOW_SCORECARD_COMMENT_PARAMETER_TYPE,
                value: allowScorecardComment,
              },
              {
                alias: ENABLE_SELF_ASSESSMENT_PARAMETER_TYPE,
                value:
                  selfAssessmentSetup === SelfAssessmentSetups.request ||
                  selfAssessmentSetup === SelfAssessmentSetups.calculate,
              },
            ],
          });
        }
      } else if (sendAllFields) {
        Object.assign(goalFields, {
          params: [
            {
              alias: ALLOW_SCORECARD_OWNER_EDITS_PARAMETER_TYPE,
              value: allowScorecardOwnerEdits,
            },
            {
              alias: CALCULATE_SELF_ASSESSMENT_PARAMETER_TYPE,
              value: selfAssessmentSetup === SelfAssessmentSetups.calculate,
            },
            {
              alias: ALLOW_SCORECARD_COMMENT_PARAMETER_TYPE,
              value: allowScorecardComment,
            },
            {
              alias: ENABLE_SELF_ASSESSMENT_PARAMETER_TYPE,
              value:
                selfAssessmentSetup === SelfAssessmentSetups.request ||
                selfAssessmentSetup === SelfAssessmentSetups.calculate,
            },
          ],
        });
      }

      if (sendAllFields) {
        Object.assign(goalFields, {
          orderRank: goalIndex + 1,
        });
      }

      const isCuSat = isCuSatGoal(goal);
      if (isCuSat) {
        const { fromGoalTemplateId } = goal;
        if (fromGoalTemplateId) {
          Object.assign(goalFields, { fromGoalTemplateId });
        } else if (id) {
          Object.assign(goalFields, { id });
        }
      } else {
        if (sendAllFields) {
          Object.assign(goalFields, { name, description });
        }
        const children = subgoals.map(
          (
            {
              weight: subgoalWeight = 0,
              id: subId,
              name: subName,
              description: subDesc,
              assessmentScaleGroupId,
            },
            subgoalIndex,
          ) => {
            const subgoalFields = {};
            if (sendSubgoalWeightField) {
              Object.assign(subgoalFields, {
                weight: Number.parseInt(subgoalWeight as string, 10),
              });
            }
            if (subId) {
              Object.assign(subgoalFields, { id: subId });
            }
            if (sendAllFields) {
              Object.assign(subgoalFields, {
                orderRank: subgoalIndex,
                name: subName,
                description: subDesc,
                assessmentScaleGroupId,
              });
            }
            return subgoalFields as FormSubGoal;
          },
        );
        Object.assign(goalFields, { subgoals: children });
      }

      return goalFields as FormGoal;
    }),
  };
}
