import type { RoadmapState } from './interfaces';
import { SavingStatus } from './interfaces';

export const storeName = 'roadmap';

export const roadmapInitialState: RoadmapState = {
  loading: true,
  isNewNodeDrawerOpen: false,
  saving: { time: 0, status: SavingStatus.IDLE },
  filters: {
    isDrawerOpen: false,
    data: {},
  },
  detailsDrawer: {
    node: null,
    isOpen: false,
  },
  editDrawer: {
    isOpen: false,
    node: null,
  },
  data: {
    nodes: [],
    edges: [],
  },
};
