import { createSlice, type Reducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import { roadmapInitialState, storeName } from './config';
import addCommonCases from '../addCommonCases';
import { roadmapData, saveChanges } from './actions';
import { SavingStatus } from './interfaces';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const roadmap = createSlice({
  name: storeName,
  initialState: cloneDeep(roadmapInitialState),
  reducers: {
    openEditDrawer: (state, action) => {
      state.editDrawer = {
        isOpen: true,
        node: cloneDeep(action.payload),
      };
    },
    closeEditDrawer: (state) => {
      state.editDrawer.isOpen = false;
    },
    openDetailsDrawer: (state, action) => {
      state.detailsDrawer = {
        isOpen: true,
        node: cloneDeep(action.payload),
      };
    },
    closeDetailsDrawer: (state) => {
      state.detailsDrawer.isOpen = false;
    },
    toggleFiltersDrawer: (state) => {
      state.filters.isDrawerOpen = !state.filters.isDrawerOpen;
    },
    toggleNewNodeDrawer: (state) => {
      state.isNewNodeDrawerOpen = !state.isNewNodeDrawerOpen;
    },
  },
  extraReducers: (builder) => {
    addCommonCases(builder, roadmapInitialState)
      .addCase(roadmapData.pending, (state) => {
        state.loading = true;
      })
      .addCase(roadmapData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(roadmapData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(saveChanges.pending, (state) => {
        state.saving.status = SavingStatus.LOADING;
      })
      .addCase(saveChanges.fulfilled, (state) => {
        state.saving.status = SavingStatus.SUCCESS;
        state.saving.time = Date.now();
      })
      .addCase(saveChanges.rejected, (state) => {
        state.saving.status = SavingStatus.ERROR;
      });
  },
});

export const {
  openDetailsDrawer,
  closeDetailsDrawer,
  openEditDrawer,
  closeEditDrawer,
  toggleFiltersDrawer,
  toggleNewNodeDrawer,
} = roadmap.actions;

export default roadmap.reducer as Reducer<typeof roadmapInitialState>;
