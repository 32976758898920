import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_CAREER_ROADMAP_SCHEMA } from 'constants/api';
import { storeName } from '../config';
import { RoadmapData } from '../interfaces';

export const saveChanges = createAsyncThunk<RoadmapData, RoadmapData>(
  `${storeName}/save`,
  (payload, thunkAPI) => {
    // TODO: temporary solution while we dont have proper env for development
    const isLocalhost = window.location.hostname === 'localhost';

    if (isLocalhost) {
      return payload;
    }

    return api.axios
      .put<Record<'schema', RoadmapData>>(API_CAREER_ROADMAP_SCHEMA, {
        schema: payload,
      })
      .then((res) => res.data.schema)
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data));
  },
);
