import { useScorecardContext } from 'contexts';
import { useScorecardData } from 'hooks';
import { SelectChangeEvent } from '@mui/material';
import { useCallback } from 'react';
import { useAppDispatch } from 'store';
import { patchScorecard } from 'store/scorecards';

export default function useMultiplierChangeHandler(goalId: number) {
  const dispatch = useAppDispatch();
  const { store, id } = useScorecardContext();
  const { goals } = useScorecardData();
  return useCallback(
    ({ target: { value } }: SelectChangeEvent<number>) => {
      const newValue = typeof value === 'string' ? parseInt(value, 10) : value;
      const goalsArr = goals.map(
        ({ extraMultiplier, id: goalsItemId, subgoals }) => ({
          extraMultiplierId:
            goalsItemId === goalId ? newValue : extraMultiplier?.id || null,
          id: goalsItemId,
          subgoals: subgoals.map((subgoal) => ({ id: subgoal.id })),
        }),
      );
      dispatch(patchScorecard({ store, id, data: { goals: goalsArr } }));
    },
    [dispatch, goalId, goals, id, store],
  );
}
