import { createSlice, Reducer } from '@reduxjs/toolkit';
import addCommonCases from 'store/addCommonCases';
import addScorecardsCases from 'store/scorecards/addScorecardsCases';
import { cloneDeep } from 'lodash-es';
import { storeName, initialFilters, ownScorecardInitialState } from './config';

export * from './interfaces';

const scorecards = createSlice({
  name: storeName,
  initialState: cloneDeep(ownScorecardInitialState),
  reducers: {},
  extraReducers: (builder) => {
    addCommonCases(builder, ownScorecardInitialState);
    addScorecardsCases(builder, storeName, initialFilters);
  },
});

export default scorecards.reducer as Reducer<typeof ownScorecardInitialState>;
