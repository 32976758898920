import { useScorecardContext } from 'contexts';
import { useCallback, useMemo, useState } from 'react';
import { useScorecardData, useScorecardUpdate, useToast } from 'hooks';
import api from 'api';
import { FeedbackRequestGoalRO } from 'store/interfaces';
import { generateURL } from 'helpers';
import { API_SCORECARD_FEEDBACK_REQUEST_RESEND_NOTIFICATION } from 'constants/api';
import { FEEDBACK_REQUEST_DATE_KEY } from 'constants/app';
import { errorTexts } from 'i18n';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectProfileInfo } from 'store/profile';
import { FEEDBACK_REQUEST_STATUS_PENDING } from 'constants/feedbacks';
import useLocalStorage from 'use-local-storage';
import { AxiosError } from 'axios';

export default function useResendFeedback(
  userId: number,
  goalId: number,
  currentDate: string,
) {
  const { id: applicationUserId } = useSelector(selectProfileInfo);
  const showMessage = useToast();
  const { formatMessage } = useIntl();
  const [requesting, setRequesting] = useState(false);
  const { id: scorecardId } = useScorecardContext();
  const { updateData } = useScorecardUpdate();
  const { feedbackRequests, feedbacks } = useScorecardData();
  const feedback = useMemo(
    () => feedbacks.find((f) => f.user.id === userId)!,
    [feedbacks, userId],
  );
  const userFullName = feedback.user.fullName;
  const feedbackGoalHeader = useMemo(
    () =>
      feedback.feedbackGoalHeaders.find(
        ({ targetScorecardGoal }) => targetScorecardGoal.id === goalId,
      )!,
    [feedback, goalId],
  );

  const hasSubgoalWithoutFeedback = useMemo(
    () =>
      feedbackGoalHeader.feedbackSubgoalHeaders.some(
        ({ assessmentScoreOriginal }) => assessmentScoreOriginal === null,
      ),
    [feedbackGoalHeader],
  );
  const { id: feedbackRequestId, status: feedbackRequestStatus } = useMemo(
    () => feedbackRequests.find(({ user }) => user.id === userId)!,
    [feedbackRequests, userId],
  );
  const showResendFeedbackButton =
    hasSubgoalWithoutFeedback &&
    feedbackRequestStatus === FEEDBACK_REQUEST_STATUS_PENDING;

  const key = `${FEEDBACK_REQUEST_DATE_KEY}.${applicationUserId}.${scorecardId}.${feedbackRequestId}`;
  const [lastRequestedDate, setLastRequestedDate] = useLocalStorage(key, '', {
    serializer: (o) => o ?? '',
    parser: (str) => str ?? '',
  });

  const alreadyRequested =
    lastRequestedDate && lastRequestedDate === currentDate;

  const resendFeedbackRequest = useCallback(async () => {
    setRequesting(true);
    return api.axios
      .post<FeedbackRequestGoalRO[]>(
        generateURL(API_SCORECARD_FEEDBACK_REQUEST_RESEND_NOTIFICATION, {
          id: feedbackRequestId,
        }),
      )
      .then(({ data: goals }) => {
        showMessage({
          severity: 'success',
          message: formatMessage(
            {
              id: 'pages.scorecards.resendFeedbackSuccess',
              defaultMessage: `Feedback request was successfully resent to {userName} for the following goals:{br}{goals}`,
            },
            {
              br: '\n',
              userName: userFullName,
              goals: goals
                .map((g) => {
                  if (
                    g.targetScorecardGoal &&
                    typeof g.targetScorecardGoal === 'object'
                  ) {
                    return `• ${g.targetScorecardGoal.name}`;
                  }
                  return null;
                })
                .filter((n) => n !== null)
                .join('\n'),
            },
          ),
        });
        setLastRequestedDate(currentDate);
      })
      .catch((error: AxiosError<{ description: string }>) => {
        showMessage({
          severity: 'error',
          message: {
            ...errorTexts.somethingWentWrongContactSupport,
            values: {
              code: error.message,
              description: error.response?.data.description,
            },
          },
        });
        updateData();
      })
      .finally(() => {
        setRequesting(false);
      });
  }, [
    userFullName,
    feedbackRequestId,
    updateData,
    showMessage,
    formatMessage,
    setLastRequestedDate,
    currentDate,
  ]);

  return {
    showResendFeedbackButton,
    requesting,
    alreadyRequested,
    resendFeedbackRequest,
  };
}
