import palette from 'styles/theme/palette';

export default function SkillNodeIcon() {
  return (
    <svg fill="none" viewBox="0 0 143 64" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" width="134" height="64" rx="9" fill="currentColor" />
      <rect
        x="8.82353"
        y="4"
        width="126"
        height="56"
        rx="6"
        fill={palette.grey.A300}
        stroke={palette.common.white}
        strokeWidth="4"
      />
      <rect
        x="22.8403"
        y="13.7808"
        width="90.3992"
        height="7.39726"
        rx="3.69863"
        fill={palette.text.primary}
      />
      <rect
        x="22.8403"
        y="26.3836"
        width="54.3487"
        height="7.39726"
        rx="3.69863"
        fill={palette.text.primary}
      />
      <circle cx="138" cy="32" r="5" fill={palette.common.white} />
      <circle
        cx="138"
        cy="32"
        r="2"
        fill={palette.common.white}
        stroke="currentColor"
        strokeWidth="2"
      />
      <circle cx="5.79999" cy="32" r="5" fill={palette.common.white} />
      <circle
        cx="5.79999"
        cy="32"
        r="2"
        fill={palette.common.white}
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}
