import { useSelector } from 'react-redux';
import { selectRoles } from 'store/profile';
import { ROLE_PEOPLE_PARTNER } from 'constants/roles';

export default function useUserRoles() {
  const roles = useSelector(selectRoles);
  const isPeoplePartner = roles.includes(ROLE_PEOPLE_PARTNER);

  return {
    isPeoplePartner,
  };
}
