import { createSlice, isAnyOf, Reducer } from '@reduxjs/toolkit';
import { isRequestVisible } from 'helpers/feedbacks';
import { cloneDeep } from 'lodash-es';
import { storeName, feedbackRequestsInitialState } from './config';
import { getFeedbackRequestsList, changePage, updateFeedback } from './actions';
import addCommonCases from '../addCommonCases';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const feedbackRequests = createSlice({
  name: storeName,
  initialState: cloneDeep(feedbackRequestsInitialState),
  reducers: {},
  extraReducers: (builder) => {
    addCommonCases(builder, feedbackRequestsInitialState)
      .addCase(getFeedbackRequestsList.fulfilled, (state, action) => {
        Object.assign(state, {
          items: action.payload.list,
          pagination: action.payload.pagination,
          loading: false,
        });
      })
      .addCase(changePage, (state, { payload }) => {
        state.pagination.page = payload;
      });
    builder.addMatcher(
      isAnyOf(updateFeedback.fulfilled),
      (state, { payload }) => {
        const index = state.items.findIndex(({ id }) => id === payload.id);
        const isVisible = isRequestVisible(payload);
        if (index > -1) {
          if (isVisible) {
            state.items[index] = payload;
          } else {
            state.items.splice(index, 1);
          }
        }
        state.loading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(getFeedbackRequestsList.pending, updateFeedback.pending),
      (state) => {
        state.loading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getFeedbackRequestsList.rejected,
        getFeedbackRequestsList.fulfilled,
        updateFeedback.rejected,
      ),
      (state) => {
        state.loading = false;
      },
    );
  },
});

export default feedbackRequests.reducer as Reducer<
  typeof feedbackRequestsInitialState
>;
