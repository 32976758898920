import { createSlice, Reducer } from '@reduxjs/toolkit';
import {
  addScorecardsCases,
  addCareerMentorOptionsCases,
  removeValueFromFilter,
} from 'store/scorecards';
import addCommonCases from 'store/addCommonCases';
import { cloneDeep } from 'lodash-es';
import { storeName, departmentInitialState, initialFilters } from './config';

export * from './interfaces';

const directReportScorecards = createSlice({
  name: storeName,
  initialState: cloneDeep(departmentInitialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      removeValueFromFilter,
      (state, { payload: { store, data } }) => {
        if ('key' in data && 'value' in data) {
          if (store.indexOf(storeName) > -1) {
            const filterValues = state.filters[data.key];
            if (Array.isArray(filterValues)) {
              const filtered = filterValues.filter(
                (value) => value !== data.value,
              );
              Object.assign(state, {
                filters: { ...state.filters, [data.key]: filtered },
                init: true,
              });
            }
          }
        }
      },
    );
    addCareerMentorOptionsCases(builder, storeName);
    addCommonCases(builder, departmentInitialState);
    addScorecardsCases(builder, storeName, initialFilters);
  },
});

export default directReportScorecards.reducer as Reducer<
  typeof departmentInitialState
>;
