import { FeedbackRequestsState } from './interfaces';

export const feedbackRequestsInitialState: FeedbackRequestsState = {
  items: [],
  loading: false,
  pagination: {
    limit: 0,
    page: 1,
    total: 0,
  },
};

export const storeName = 'feedbackRequests';
