import { RootState } from 'store';

export const selectIsLoggedIn = (state: RootState) => state.profile.loggedIn;

export const selectFeedbackDialogState = (state: RootState) =>
  state.profile.feedbackDialogActive;

export const selectIsProfileInit = (state: RootState) => state.profile.init;

export const selectProfileInfo = (state: RootState) => state.profile.info;

export const selectUserCanUseApp = ({
  profile: {
    info: { enabled, isBanned },
  },
}: RootState) => enabled && !isBanned;

export const selectRoles = (state: RootState) => state.profile.info.roles;

export const selectToken = (state: RootState) => state.profile.token;
