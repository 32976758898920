import { ProfileInfo, ProfileState } from './interfaces';

export const initialInfo: ProfileInfo = {
  id: null,
  avatar: null,
  email: '',
  enabled: false,
  isBanned: false,
  lastHeartbeatAt: null,
  username: '',
  fullName: '',
  roles: [],
};

export const profileInitialState: ProfileState = {
  loggedIn: false,
  init: false,
  info: { ...initialInfo },
  feedbackDialogActive: false,
  token: '',
};

export const storeName = 'profile';
