import { useForm } from 'react-hook-form';
import { FormValues } from 'components/ScorecardDetails/interfaces';
import {
  formatScorecardData,
  handleResponseErrors,
  parseScorecardData,
  getScorecardStates,
  isFormDirty,
} from 'helpers';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import commonMessages from 'i18n/common';
import { patchScorecard } from 'store/scorecards';
import {
  CatchCallback,
  SuccessCallback,
  useActionPipeline,
  useToast,
  usePagePermissions,
  useResetAfterSubmit,
  usePrompt,
} from 'hooks';
import { Scorecard, ScorecardStores } from 'store/interfaces';
import { commonTexts } from 'i18n';

export default function useScorecardFormValues(
  scorecard: Scorecard,
  store: ScorecardStores,
) {
  const { formatMessage } = useIntl();
  const { goals, id, status } = scorecard;
  const {
    isFeedbackRequested,
    isFeedbackCollected,
    isReadyForReview,
    isActive,
  } = getScorecardStates(status);

  const form = useForm<FormValues>({
    defaultValues: {
      goals: parseScorecardData(goals),
      id,
      totalWeight: 0,
      entity: 'scorecard',
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const {
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
    formState,
  } = form;
  const isDirty = isFormDirty(formState);

  useResetAfterSubmit(form);
  usePrompt(formatMessage(commonTexts.unsavedChangesPrompt), isDirty);

  useEffect(() => {
    goals.forEach(({ weight }, index) => {
      setValue(`goals.${index}.weight`, weight);
    });
  }, [setValue, goals]);
  const goalsFields = watch('goals');
  const showMessage = useToast();
  const canSubmit =
    isDirty && goalsFields?.length && !Object.keys(errors).length;
  const { isOwnScorecardsPage } = usePagePermissions();
  const withRestrictions =
    isOwnScorecardsPage ||
    isFeedbackCollected ||
    isFeedbackRequested ||
    isReadyForReview;

  const isActiveOwnScorecard = isActive && isOwnScorecardsPage;

  const onSuccess = useCallback<SuccessCallback<Scorecard>>(
    ({ payload: { goals: updatedGoals } }) => {
      parseScorecardData(updatedGoals).forEach((goal, index) => {
        setValue(`goals.${index}`, goal);
      });

      showMessage({
        severity: 'success',
        message: formatMessage(commonMessages.changesSavedSuccessfully),
      });
    },
    [formatMessage, setValue, showMessage],
  );
  const onCatch = useMemo<CatchCallback>(
    () => handleResponseErrors<FormValues>(setError),
    [setError],
  );
  const actionPipeline = useActionPipeline<Scorecard>(onSuccess, onCatch);

  const onSubmit = useCallback(
    (data: FormValues) => {
      actionPipeline(
        patchScorecard({
          id,
          store,
          data: formatScorecardData(
            data,
            withRestrictions,
            isActiveOwnScorecard,
          ),
        }),
      );
    },
    [actionPipeline, id, store, withRestrictions, isActiveOwnScorecard],
  );

  const onSubmitClick = useMemo(
    () => handleSubmit(onSubmit),
    [handleSubmit, onSubmit],
  );
  return {
    onSubmitClick,
    form,
    canSubmit,
  };
}
