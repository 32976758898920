import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_COUNTRY_STATS } from 'constants/api';
import { storeName } from '../config';
import { CountryResponse } from '../interfaces/CountryStats';

export const updateCountries = createAsyncThunk(
  `${storeName}/updateCountries`,
  (data: { unitId: number | null }) => {
    const { unitId } = data;

    if (!unitId) {
      return Promise.resolve([]);
    }

    return api.axios
      .get(`${API_COUNTRY_STATS}?unitId=${unitId}`)
      .then((res) => (res.data as CountryResponse)?.items || [])
      .catch(() => [])
      .then((res) =>
        res
          .map((item) => ({ id: item.country.id, name: item.country.name }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      );
  },
);
