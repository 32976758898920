import {
  URL_MY_SCORECARDS,
  URL_MY_TEAM,
  URL_MY_TEAM_DIRECT_REPORTS,
  URL_MY_TEAM_SUBORDINATES,
  URL_REVIEW_CYCLES,
  URL_FEEDBACK_REQUESTS,
  URL_DEPARTMENT_OVERVIEW,
  URL_CREATE_SCORECARD_TEMPLATE,
  URL_LIST,
  URL_EDIT_SCORECARD_TEMPLATE,
  URL_EDIT_SCORECARD,
  URL_CAREER_ROADMAP,
} from 'constants/urls';
import {
  ROLE_EMPLOYEE,
  ROLE_CAREER_MENTOR,
  ROLE_PEOPLE_PARTNER,
  ROLE_DIRECTOR,
} from 'constants/roles';
import MyScorecards from 'pages/MyScorecards';
import {
  myTeamTexts,
  reviewCyclesTexts,
  scorecardsTexts,
  feedbackRequestsTexts,
  departmentOverviewTexts,
  careerRoadmapTexts,
} from 'i18n';
import ReviewCycles from 'pages/ReviewCycles';
import FeedbackRequests from 'pages/FeedbackRequests';
import DepartmentOverview from 'pages/DepartmentOverview';
import { DirectReports, Subordinates } from 'pages/MyTeam';
import {
  CreateScorecardTemplate,
  EditScorecardTemplate,
} from 'pages/ScorecardTemplate';
import EditScorecard from 'pages/Scorecard';
import CareerRoadmap from 'pages/CareerRoadmap';
import { AppRouteItem } from './interfaces';

const routes: AppRouteItem[] = [
  {
    path: URL_REVIEW_CYCLES,
    title: reviewCyclesTexts.title,
    roles: [ROLE_PEOPLE_PARTNER],
    Component: ReviewCycles,
    routes: [
      {
        path: URL_LIST,
        roles: [ROLE_PEOPLE_PARTNER],
      },
      {
        path: URL_CREATE_SCORECARD_TEMPLATE,
        roles: [ROLE_PEOPLE_PARTNER],
        Component: CreateScorecardTemplate,
      },
      {
        path: URL_EDIT_SCORECARD_TEMPLATE,
        roles: [ROLE_PEOPLE_PARTNER],
        Component: EditScorecardTemplate,
      },
    ],
  },
  {
    path: URL_MY_SCORECARDS,
    title: scorecardsTexts.title,
    roles: [ROLE_EMPLOYEE],
    Component: MyScorecards,
    routes: [
      {
        path: URL_LIST,
        roles: [ROLE_EMPLOYEE],
      },
      {
        path: URL_EDIT_SCORECARD,
        roles: [ROLE_EMPLOYEE],
        Component: EditScorecard,
      },
    ],
  },
  {
    path: URL_MY_TEAM,
    title: myTeamTexts.title,
    roles: [ROLE_CAREER_MENTOR],
    routes: [
      {
        path: URL_MY_TEAM_DIRECT_REPORTS,
        title: myTeamTexts.directReports,
        roles: [ROLE_CAREER_MENTOR],
        Component: DirectReports,
        routes: [
          {
            path: URL_LIST,
            roles: [ROLE_CAREER_MENTOR],
          },
          {
            path: URL_EDIT_SCORECARD,
            roles: [ROLE_CAREER_MENTOR],
            Component: EditScorecard,
          },
        ],
      },
      {
        path: URL_MY_TEAM_SUBORDINATES,
        title: myTeamTexts.subordinates,
        roles: [ROLE_CAREER_MENTOR],
        Component: Subordinates,
        routes: [
          {
            path: URL_LIST,
            roles: [ROLE_CAREER_MENTOR],
          },
          {
            path: URL_EDIT_SCORECARD,
            roles: [ROLE_CAREER_MENTOR],
            Component: EditScorecard,
          },
        ],
      },
    ],
  },
  {
    path: URL_FEEDBACK_REQUESTS,
    title: feedbackRequestsTexts.title,
    roles: [ROLE_EMPLOYEE],
    Component: FeedbackRequests,
  },
  {
    path: URL_DEPARTMENT_OVERVIEW,
    title: departmentOverviewTexts.title,
    roles: [ROLE_DIRECTOR, ROLE_PEOPLE_PARTNER],
    Component: DepartmentOverview,
    routes: [
      {
        path: URL_LIST,
        roles: [ROLE_DIRECTOR, ROLE_PEOPLE_PARTNER],
      },
      {
        path: URL_EDIT_SCORECARD,
        roles: [ROLE_DIRECTOR, ROLE_PEOPLE_PARTNER],
        Component: EditScorecard,
      },
    ],
  },
  {
    path: URL_CAREER_ROADMAP,
    title: careerRoadmapTexts.title,
    roles: [ROLE_EMPLOYEE, ROLE_DIRECTOR, ROLE_PEOPLE_PARTNER],
    Component: CareerRoadmap,
    emails: ['imykhailychenko@star.global', 'ozmiiuk@star.global'],
  },
];

export default routes;
