import { AppRouteComponentProps } from 'components/AppRoutes';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectProfileInfo } from 'store/profile';
import { ROLE_EMPLOYEE } from 'constants/roles';

export default function usePageAccessible({
  currentRoute: { roles, emails },
}: AppRouteComponentProps) {
  const { roles: userRoles, email } = useSelector(selectProfileInfo);

  return useMemo(() => {
    if (emails?.length && !emails.includes(email)) {
      return false;
    }
    if (!roles?.length) {
      return true;
    }
    if (!userRoles?.includes(ROLE_EMPLOYEE)) {
      return false;
    }
    return roles.some((requiredRole) => userRoles.includes(requiredRole));
  }, [roles, userRoles, email, emails]);
}
