import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Scorecard,
  ScorecardAssessmentAction,
  ScorecardsActionStoreArgument,
} from 'store/interfaces';
import api from 'api';
import { API_SCORECARD_APPLY } from 'constants/api';
import { generateURL } from 'helpers';
import { updateFullData } from './updateFullData';

export const changeStatus = createAsyncThunk<
  Scorecard,
  {
    id: number;
    store: ScorecardsActionStoreArgument;
    action: ScorecardAssessmentAction;
  }
>('scorecards/changeStatus', async ({ id, action, store }, thunkAPI) =>
  api.axios
    .patch<Scorecard>(generateURL(API_SCORECARD_APPLY, { id }), { action })
    .then(({ data }) => {
      thunkAPI.dispatch(updateFullData({ id, store }));
      return data;
    })
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
