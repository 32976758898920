import { useScorecardStates } from 'hooks';
import usePagePermissions from './usePagePermissions';

export default function useReviewersConfiguration() {
  const {
    isGoalsPrepared,
    isActive,
    isFeedbackRequested,
    isFeedbackCollected,
    isCompleted,
    isGoalsApproved,
  } = useScorecardStates();
  const { hasFullAccess, isOwnScorecardsPage } = usePagePermissions();

  const showButtonForCM =
    hasFullAccess &&
    (isActive ||
      isGoalsPrepared ||
      isGoalsApproved ||
      isFeedbackRequested ||
      isFeedbackCollected);
  const showButtonForUser =
    isOwnScorecardsPage && (isActive || isGoalsPrepared || isGoalsApproved);
  const showAddButton = !isCompleted && (showButtonForUser || showButtonForCM);
  const showReadOnlyReviewerFeedback = isCompleted;

  const renderPaletteForUser = isOwnScorecardsPage && !isCompleted;
  const renderPaletteForCM =
    hasFullAccess && (isActive || isGoalsPrepared || isGoalsApproved);
  const renderPalette = renderPaletteForUser || renderPaletteForCM;

  return {
    showAddButton,
    showReadOnlyReviewerFeedback,
    renderPalette,
  };
}
