import type { Node } from 'reactflow';
import type { RootState } from 'store';
import type { NodeData, RoadmapData } from './interfaces';
import { SavingStatus } from './interfaces';

export const selectRoadmapLoading = (state: RootState): boolean =>
  state.roadmap.loading;

export const selectRoadmapData = (state: RootState): RoadmapData =>
  state.roadmap.data;

export const selectRoadmapNodes = (state: RootState): Node<NodeData>[] =>
  state.roadmap.data.nodes;

export const selectEditDrawerState = (
  state: RootState,
): Node<NodeData> | null => state.roadmap.editDrawer.node;

export const selectIsEditDrawerOpen = (state: RootState): boolean =>
  state.roadmap.editDrawer.isOpen;

export const selectIsFiltersDrawerOpen = (state: RootState): boolean =>
  state.roadmap.filters.isDrawerOpen;

export const selectDetailsDrawerState = (
  state: RootState,
): Node<NodeData> | null => state.roadmap.detailsDrawer.node;

export const selectIsDetailsDrawerOpen = (state: RootState): boolean =>
  state.roadmap.detailsDrawer.isOpen;

export const selectIsNewNodeDrawerOpen = (state: RootState): boolean =>
  state.roadmap.isNewNodeDrawerOpen;

export const selectSavingStatus = (state: RootState): SavingStatus =>
  state.roadmap.saving.status;

export const selectSavingTime = (state: RootState): number =>
  state.roadmap.saving.time;
