import { useCallback } from 'react';
import {
  SuccessCallback,
  useActionPipeline,
  useDialogState,
  useToast,
} from 'hooks';
import { useIntl } from 'react-intl';
import { commonTexts } from 'i18n';
import { changeStatus } from 'store/scorecards';
import { SCORECARD_ACTION_GOALS_REDRAFT } from 'constants/scorecards';
import { useScorecardContext } from 'contexts';

export default function useRevertToActive() {
  const { id, store } = useScorecardContext();
  const [
    isRevertToActiveDialogActive,
    openRevertToActiveDialog,
    onRevertToActiveDialogClose,
  ] = useDialogState();

  const onRevertToActiveClick = useCallback(() => {
    openRevertToActiveDialog();
  }, [openRevertToActiveDialog]);

  const { formatMessage } = useIntl();
  const showMessage = useToast();
  const onRevertToActiveSuccess = useCallback<SuccessCallback>(() => {
    showMessage({
      severity: 'success',
      message: formatMessage(commonTexts.success),
    });
  }, [showMessage, formatMessage]);

  const revertToActivePipeline = useActionPipeline(
    onRevertToActiveSuccess,
    null,
    onRevertToActiveDialogClose,
  );

  const onRevertToActiveSubmit = useCallback(() => {
    revertToActivePipeline(
      changeStatus({
        id,
        store,
        action: SCORECARD_ACTION_GOALS_REDRAFT,
      }),
    );
  }, [id, revertToActivePipeline, store]);
  return {
    onRevertToActiveClick,
    isRevertToActiveDialogActive,
    onRevertToActiveDialogClose,
    onRevertToActiveSubmit,
  };
}
