import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { CommonGoalByUnit } from './interfaces';

export const selectCommonGoals = createSelector(
  (state: RootState) => state.commonGoals,
  (data) => data,
);

export const selectCommonGoalsByUnit = createSelector(
  (state: RootState) => state.commonGoals,
  (data) => {
    const byUnit: Record<string, CommonGoalByUnit> = {};
    data.items.forEach((item) => {
      const unitName = item.unit?.fullName || '-';

      if (!byUnit[unitName]) {
        byUnit[unitName] = {
          id: item.unit?.id || 0,
          name: unitName,
          templates: [],
        };
      }
      byUnit[unitName].templates.push(item);
    });

    return {
      items: Object.values(byUnit),
      loading: data.loading,
    };
  },
);
