import { useMatch } from 'react-router-dom';
import {
  URL_DEPARTMENT_OVERVIEW,
  URL_MY_SCORECARDS,
  URL_MY_TEAM,
  URL_MY_TEAM_DIRECT_REPORTS,
  URL_MY_TEAM_SUBORDINATES,
} from 'constants/urls';
import {
  ownScorecards,
  directScorecards,
  subordinatesScorecards,
  departmentScorecards,
} from 'store/scorecards';

export default function useScorecardFormVariant() {
  const isOwnScorecardsPage = useMatch(`${URL_MY_SCORECARDS}/*`) !== null;
  const isMySubordinatesPage =
    useMatch(`${URL_MY_TEAM}/${URL_MY_TEAM_SUBORDINATES}/*`) !== null;
  const isMyDirectReportsPage =
    useMatch(`${URL_MY_TEAM}/${URL_MY_TEAM_DIRECT_REPORTS}/*`) !== null;
  const isDepartmentOverviewPage =
    useMatch(`${URL_DEPARTMENT_OVERVIEW}/*`) !== null;

  if (isMyDirectReportsPage) {
    return directScorecards;
  }

  if (isMySubordinatesPage) {
    return subordinatesScorecards;
  }

  if (isDepartmentOverviewPage) {
    return departmentScorecards;
  }

  if (isOwnScorecardsPage) {
    return ownScorecards;
  }

  return ownScorecards;
}
