import { OptionsState } from './interfaces';
import { MainOptions } from './interfaces/OptionsState';

export const storeName = 'options';

export const mainOptions: MainOptions = {
  units: [],
  assessmentScaleGroups: [],
  locations: [],
  reviewCycles: [],
};

export const optionsInitialState: OptionsState = {
  ...mainOptions,
  assessmentScales: {},
  extraMultipliers: [],
  countries: [],
};
