import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RawIntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { logout, profileSet } from 'store/profile/actions';
import intl from 'i18n';
import store from 'store';
import theme from 'styles/theme';
import PageLoader from 'components/PageLoader';
import UnhandledErrorScreen from 'components/UnhandledErrorScreen';
import api, { addUnauthorizedInterceptor } from 'api';
import { ProfileRefreshTokensResponse, profileInit } from 'store/profile';
import reportWebVitals from './reportWebVitals';
import App from './App';
import isDev from './helpers/env/isDev';

addUnauthorizedInterceptor(
  api.axios,
  (profile: ProfileRefreshTokensResponse) =>
    store.dispatch(profileSet(profile)),
  () => store.dispatch(logout()),
);

if (!isDev) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_COMMIT_OR_TAG,
    environment: process.env.REACT_APP_PROJECT_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

store.dispatch(profileInit());

root.render(
  <RawIntlProvider value={intl}>
    <Provider store={store}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <CssBaseline>
              <Suspense fallback={<PageLoader open />}>
                <ErrorBoundary
                  FallbackComponent={UnhandledErrorScreen}
                  onReset={() => window.location.reload()}
                >
                  <App />
                </ErrorBoundary>
              </Suspense>
            </CssBaseline>
          </ThemeProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </Provider>
  </RawIntlProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
