import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_LOGIN_REFRESH } from 'constants/api';
import { setIsLoggedIn } from 'helpers/localStorage';
import { storeName } from '../config';
import { ProfileRefreshTokensResponse } from '../interfaces';
import { logout } from './logout';

export const profileRefreshTokens =
  createAsyncThunk<ProfileRefreshTokensResponse>(
    `${storeName}/refreshTokens`,
    async (data, thunkAPI) =>
      api.axios
        .post<ProfileRefreshTokensResponse>(API_LOGIN_REFRESH)
        .then((res) => {
          setIsLoggedIn(true);
          return res.data;
        })
        .catch((e) => {
          thunkAPI.dispatch(logout());
          return thunkAPI.rejectWithValue(e.response?.data);
        }),
  );
