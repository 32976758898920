import palette from 'styles/theme/palette';

export default function OuterNode() {
  return (
    <svg fill="none" viewBox="0 0 149 90" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="149"
        height="90"
        rx="9"
        fill="currentColor"
        fillOpacity="0.15"
      />
      <rect
        x="2"
        y="2"
        width="66"
        height="86"
        rx="8"
        fill={palette.common.white}
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="6 6"
      />
      <mask
        id="mask0_15_8"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="147"
        height="86"
      >
        <rect x="2" y="2" width="147" height="86" fill={palette.common.white} />
      </mask>
      <g mask="url(#mask0_15_8)">
        <rect
          x="-26"
          y="13"
          width="134"
          height="64"
          rx="9"
          fill={palette.common.black}
        />
        <rect
          x="-22.1765"
          y="17"
          width="126"
          height="56"
          rx="6"
          fill={palette.grey.A300}
          stroke={palette.common.white}
          strokeWidth="4"
        />
        <rect
          x="-8.15967"
          y="26.7808"
          width="90.3992"
          height="7.39726"
          rx="3.69863"
          fill={palette.text.primary}
        />
        <rect
          x="-8.15967"
          y="39.3836"
          width="54.3487"
          height="7.39726"
          rx="3.69863"
          fill={palette.text.primary}
        />
        <circle cx="107" cy="45" r="5" fill={palette.common.white} />
        <circle
          cx="107"
          cy="45"
          r="2"
          fill={palette.common.white}
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
