import { createAsyncThunk } from '@reduxjs/toolkit';
import { TemplateGoal } from 'store/interfaces';
import api from 'api';
import { API_COMMON_GOAL_TEMPLATES } from 'constants/api';
import { storeName } from '../config';

export const getCommonGoalsList = createAsyncThunk<{
  items: TemplateGoal[];
}>(`${storeName}/getCommonGoalsList`, async (data, thunkAPI) =>
  api.axios
    .get<{ items: TemplateGoal[] }>(API_COMMON_GOAL_TEMPLATES)
    .then((res) => {
      const { items } = res.data;

      return {
        items,
      };
    })
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
