import type { Node } from 'reactflow';
import type { NodeData, RoadmapData } from 'store/roadmap';
import { NodeTypesEnum } from 'constants/roadmap';
import { omit, pick, cloneDeep } from 'lodash-es';
import EdgeNormalizer from './edgeNormalizer';

export default class NodeNormalizer {
  static readonly NODE_FIELDS: string[] = [
    'id',
    'type',
    'data',
    'width',
    'style',
    'height',
    'zIndex',
    'position',
    'positionAbsolute',
    'parentNode',
    'expandParent',
    'sourcePosition',
    'targetPosition',
  ];

  private node: Node<NodeData>;

  constructor(node: Node<NodeData>) {
    this.node = cloneDeep(node);
  }

  deleteUnusedData = (): void => {
    this.node = pick(this.node, NodeNormalizer.NODE_FIELDS) as Node<NodeData>;

    if (!this.node.expandParent) {
      delete this.node.parentNode;
    }
  };

  normalizeGroupNode = (): void => {
    this.node.zIndex = 0;
    this.node = omit(this.node, ['parentNode', 'expandParent']);
    this.node.data = pick(this.node.data, ['title', 'edges']) as NodeData;
  };

  normalizeRootNode = (): void => {
    this.node.zIndex = 4;
    this.node = omit(this.node, 'style');
    this.node.data = pick(this.node.data, ['title', 'edges']) as NodeData;
  };

  normalizeSkillNode = (): void => {
    this.node.zIndex = 4;
    this.node = omit(this.node, 'style');
  };

  static normalize(node: Node<NodeData>): Node<NodeData> {
    const normalizer = new NodeNormalizer(node);
    normalizer.deleteUnusedData();

    switch (normalizer.node.type) {
      case NodeTypesEnum.ROOT:
        normalizer.normalizeRootNode();
        break;

      case NodeTypesEnum.SKILL:
        normalizer.normalizeSkillNode();
        break;

      case NodeTypesEnum.GROUP:
        normalizer.normalizeGroupNode();
        break;

      default:
        throw new Error('Unknown node type');
    }

    return normalizer.node;
  }

  static normalizeCanvasState({ nodes, edges }: RoadmapData): RoadmapData {
    return {
      nodes: nodes.map(NodeNormalizer.normalize),
      edges: EdgeNormalizer.reduce(edges),
    };
  }
}
