// Main routes
export const URL_REVIEW_CYCLES = 'review-cycles';
export const URL_MY_SCORECARDS = 'my-scorecards';
export const URL_MY_TEAM = 'my-team';
export const URL_FEEDBACK_REQUESTS = 'feedback-requests';
export const URL_DEPARTMENT_OVERVIEW = 'department-overview';
export const URL_CAREER_ROADMAP = 'career-roadmap';

// Sub routes
export const URL_MY_TEAM_SUBORDINATES = `subordinates`;
export const URL_MY_TEAM_DIRECT_REPORTS = `direct-reports`;

export const URL_SCORECARD_TEMPLATES = 'scorecard-templates';

export const URL_CREATE_SCORECARD_TEMPLATE = `:cycleId/${URL_SCORECARD_TEMPLATES}/create`;
export const URL_EDIT_SCORECARD_TEMPLATE = `:cycleId/${URL_SCORECARD_TEMPLATES}/:templateId/edit`;

export const URL_EDIT_SCORECARD = ':scorecardId/edit';

export const URL_LIST = 'list';
