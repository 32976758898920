import {
  Autocomplete as MUIAutocomplete,
  AutocompleteRenderOptionState,
  ListItemText,
  MenuItem,
} from '@mui/material';
import classNames from 'classnames';
import React, {
  HTMLAttributes,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Option } from 'store/options';
import TextField from 'components/TextField';
import { FormattedMessage } from 'react-intl';
import Tooltip from 'components/Tooltip';
import { CloseIcon } from 'components/Icons';
import {
  Checkbox,
  List,
  TagWrapper,
  TooltipChip,
  Wrapper,
} from './MultiselectAutocomplete.styled';
import { MultiselectAutocompleteProps } from './interfaces';

function MultiselectAutocomplete<T extends Option>({
  options,
  size = 'large',
  placeholder,
  label,
  ListboxProps = {},
  selectedOptions,
  removeSelection = () => {},
  className,
  customTagsMessage,
  isInModal,
  renderOption,
  ...props
}: MultiselectAutocompleteProps<T>) {
  const [inputClosed, setInputClosed] = useState(true);
  const [inputValue, setInputValue] = useState('');

  const renderOptionFunction = useCallback(
    (
      optionProps: HTMLAttributes<HTMLLIElement>,
      option: T,
      { selected }: AutocompleteRenderOptionState,
    ) => (
      <MenuItem {...optionProps} key={option.id || option.name}>
        <Checkbox checked={selected} />
        <ListItemText primary={option.name} />
      </MenuItem>
    ),
    [],
  );

  const isOptionEqualToValue = useCallback(
    (option: T, optionValue: T) => option.id === optionValue.id,
    [],
  );

  const renderTags = useCallback(
    (selected: T[]) => (
      <TagWrapper className="tag-wrapper">
        {selected.length > 1 ? (
          <FormattedMessage
            id={
              customTagsMessage?.defaultMessage ||
              'common.autocomplete.selectedItems'
            }
            defaultMessage={
              customTagsMessage?.defaultMessage ||
              '{count, plural, one {# item} other {# items}} selected'
            }
            values={{ count: selected.length }}
          />
        ) : (
          selected[0].name
        )}
      </TagWrapper>
    ),
    [customTagsMessage],
  );

  const selectedOptionsList = useMemo(
    () =>
      options
        .filter(({ id }) => selectedOptions?.includes(id))
        .map(({ name, id }) => (
          <TooltipChip
            key={id}
            label={name}
            onMouseDown={(e: SyntheticEvent) => e.stopPropagation()}
            onDelete={inputClosed ? () => removeSelection(id) : undefined}
            deleteIcon={<CloseIcon />}
          />
        )),
    [options, removeSelection, selectedOptions, inputClosed],
  );

  const onFocus = useCallback(() => setInputClosed(false), []);
  const onBlur = useCallback(() => {
    setInputValue('');
    setTimeout(() => setInputClosed(true), 300);
  }, []);

  const onInputChange = useCallback(
    (e: SyntheticEvent, value: string, reason: string) => {
      if (reason === 'input' || reason === 'clear') {
        setInputValue(value);
      }

      if (reason === 'clear') {
        e.stopPropagation();
      }
    },
    [],
  );

  const showTooltip =
    (inputClosed && selectedOptionsList.length > 1) ||
    (!inputClosed && selectedOptionsList.length);

  return (
    <Wrapper className={className}>
      <Tooltip
        variant="light"
        className="tooltip"
        placement={inputClosed ? 'bottom' : 'right'}
        title={selectedOptionsList}
        disableHoverListener={!inputClosed}
        componentsProps={{
          tooltip: {
            variant: 'light',
            style: {
              marginTop: inputClosed ? '10px' : '25px',
              display: showTooltip ? 'block' : 'none',
              visibility: showTooltip ? 'visible' : 'hidden',
              width: '100%',
            },
          },
          popper: {
            style: {
              zIndex: 10000,
              padding: inputClosed ? '0 20px 20px 20px' : 'unset',
            },
          },
        }}
      >
        <MUIAutocomplete
          {...props}
          multiple
          disableCloseOnSelect
          inputValue={inputValue}
          onInputChange={onInputChange}
          blurOnSelect={false}
          onBlur={onBlur}
          onFocus={onFocus}
          disableClearable={false}
          isOptionEqualToValue={isOptionEqualToValue}
          renderOption={renderOption || renderOptionFunction}
          renderTags={renderTags}
          options={options}
          ListboxProps={{
            ...ListboxProps,
            className: classNames(
              ListboxProps.className,
              `SelectMenuSize-${size}`,
            ),
          }}
          ListboxComponent={List}
          renderInput={(textFieldProps) => (
            <TextField
              {...textFieldProps}
              placeholder={placeholder}
              size={size}
              label={label}
            />
          )}
          componentsProps={{
            paper: {
              sx: {
                minWidth: 'fit-content',
              },
            },
            popper: {
              sx: {
                zIndex: isInModal ? 10000 : 999,
              },
            },
          }}
        />
      </Tooltip>
    </Wrapper>
  );
}

MultiselectAutocomplete.MultiselectAutocomplete = {
  disablePortal: true,
};

export default MultiselectAutocomplete;
