import {
  type RoadmapData,
  selectRoadmapData,
  saveChanges,
} from 'store/roadmap';
import { useSelector } from 'react-redux';
import {
  type Edge,
  type Node,
  type EdgeChange,
  type Connection,
  type NodeChange,
  addEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { useCallback, useRef } from 'react';
import { RoadmapEvents } from 'constants/roadmap';
import {
  NewNode,
  downloadJson,
  NodeNormalizer,
  UpdatedNodeBuilder,
  EdgeNormalizer,
} from 'helpers';
import { cloneDeep } from 'lodash-es';
import { useAppDispatch } from 'store';
import useSubscribe from './useSubscribe';

type OnChange<ChangesType> = (changes: ChangesType[]) => void;

interface CanvasState extends RoadmapData {
  onNodesChange: OnChange<NodeChange>;
  onEdgesChange: OnChange<EdgeChange>;
  onConnect: (params: Edge | Connection) => void;
}

export default function useCanvasState(): CanvasState {
  const ref = useRef<RoadmapData>({ nodes: [], edges: [] });

  const dispatch = useAppDispatch();
  const roadmapData = useSelector(selectRoadmapData);

  const [nodes, setNods, onNodesChange] = useNodesState(
    cloneDeep(roadmapData.nodes),
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    cloneDeep(roadmapData.edges),
  );

  // Nodes are updated very often, so in order to avoid a large number of events subscription, we need this workaround
  ref.current.nodes = nodes;
  ref.current.edges = edges;

  const onConnect = useCallback(
    (params: Edge | Connection) => {
      setEdges((prev) =>
        addEdge({ ...params, ...EdgeNormalizer.defaultEdgeConfig }, prev),
      );
    },
    [setEdges],
  );

  const saveCanvas = useCallback(() => {
    // don't remove setTimeout
    // it should be async acton to avoid react batching
    setTimeout(() => {
      dispatch(saveChanges(NodeNormalizer.normalizeCanvasState(ref.current)));
    }, 0);
  }, [dispatch]);

  const addNode = useCallback(
    (_: string, node: Node) => {
      setNods((prev) => [...prev, node]);
      saveCanvas();
    },
    [saveCanvas, setNods],
  );

  const deleteNode = useCallback(
    (_: string, id: string) => {
      setNods((prev) => prev.filter((node) => node.id !== id));
      saveCanvas();
    },
    [saveCanvas, setNods],
  );

  const copyNode = useCallback(
    (_: string, id: string) => {
      setNods((prev) => {
        const node =
          prev.find((item) => item.id === id) ?? NewNode.create().get();

        // We need to call NewNode.override in order to create new id for node copy
        return [...prev, NewNode.override(node).get()];
      });
      saveCanvas();
    },
    [saveCanvas, setNods],
  );

  const editNode = useCallback(
    (_: string, node: Node) => {
      const nodeUpdater = UpdatedNodeBuilder.setNodesList(ref.current.nodes)
        .setEdges(ref.current.edges)
        .setNewNode(node)
        .build();

      setNods(nodeUpdater.getNodes());
      setEdges(nodeUpdater.getEdges());
      saveCanvas();
    },
    [saveCanvas, setNods, setEdges],
  );

  const exportCanvas = useCallback(
    () =>
      downloadJson({
        schema: NodeNormalizer.normalizeCanvasState(ref.current),
      }),
    [],
  );

  useSubscribe<Node>(RoadmapEvents.ADD_NODE, addNode);
  useSubscribe<string>(RoadmapEvents.DELETE_NODE, deleteNode);
  useSubscribe<string>(RoadmapEvents.COPY_NODE, copyNode);
  useSubscribe<Node>(RoadmapEvents.EDIT_NODE, editNode);
  useSubscribe(RoadmapEvents.EXPORT, exportCanvas);
  useSubscribe(RoadmapEvents.SAVE, saveCanvas);

  return { nodes, edges, onNodesChange, onEdgesChange, onConnect };
}
