import { SyntheticEvent, useCallback, useState } from 'react';
import { FeedbackRequestRO } from 'store/interfaces';
import { findIndexFromIndex } from 'helpers';
import { isGoalPending } from 'helpers/feedbacks';

export default function useRequestItemTabs(data: FeedbackRequestRO) {
  const { requestGoals } = data;
  const defaultIndex = findIndexFromIndex(-1, requestGoals, isGoalPending);
  const [activeGoalIndex, setActiveGoal] = useState<number>(defaultIndex);
  const onGoalChange = useCallback(
    (event: SyntheticEvent, value: number) => {
      setActiveGoal(value);
    },
    [setActiveGoal],
  );

  const goToNextPendingGoal = useCallback(() => {
    const nexPendingIndex = findIndexFromIndex(
      activeGoalIndex,
      requestGoals,
      isGoalPending,
    );
    setActiveGoal(nexPendingIndex);
  }, [activeGoalIndex, requestGoals]);

  return {
    activeGoalIndex,
    onGoalChange,
    goToNextPendingGoal,
  };
}
