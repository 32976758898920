import { styled } from '@mui/material';

export const NodeContentStyled = styled('button')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0.85rem 0.75rem',
  borderRadius: '4px',
  cursor: 'pointer',
  textAlign: 'left',
  border: 'none',
  background: theme.palette.grey.A300,

  '&:hover': {
    background: theme.palette.grey.A400,
  },
  '&:active': {
    background: theme.palette.grey[300],
  },

  '*': {
    cursor: 'pointer',
  },
}));

NodeContentStyled.defaultProps = {
  type: 'button',
};
