import palette from 'styles/theme/palette';

export default function InnerNode() {
  return (
    <svg fill="none" viewBox="0 0 147 88" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="1"
        width="145"
        height="86"
        rx="8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="6 6"
      />
      <mask
        id="mask0_5_35"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="145"
        height="86"
      >
        <rect x="1" y="1" width="145" height="86" fill={palette.common.white} />
      </mask>
      <g mask="url(#mask0_5_35)">
        <rect
          x="-27"
          y="12"
          width="134"
          height="64"
          rx="9"
          fill={palette.common.black}
        />
        <rect
          x="-23.1765"
          y="16"
          width="126"
          height="56"
          rx="6"
          fill={palette.grey.A300}
          stroke={palette.common.white}
          strokeWidth="4"
        />
        <rect
          x="-9.15967"
          y="25.7808"
          width="90.3992"
          height="7.39726"
          rx="3.69863"
          fill={palette.text.primary}
        />
        <rect
          x="-9.15967"
          y="38.3836"
          width="54.3487"
          height="7.39726"
          rx="3.69863"
          fill={palette.text.primary}
        />
        <circle cx="106" cy="44" r="5" fill={palette.common.white} />
        <circle
          cx="106"
          cy="44"
          r="2"
          fill={palette.common.white}
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
