import { useNodes } from 'reactflow';
import { useDispatch } from 'react-redux';
import { type NodeData, openDetailsDrawer } from 'store/roadmap';
import { Stack, Typography } from '@mui/material';
import Label from 'components/Label';
import palette from 'styles/theme/palette';
import { NodeContentStyled } from './NodeContent.styled';

interface Props {
  nodeId: string;
  data: NodeData;
}
export default function NodeContent({ nodeId, data }: Props) {
  const dispatch = useDispatch();
  const nodes = useNodes<NodeData>();

  const handleOpenDrawer = (): void => {
    const node = nodes.find((item) => item.id === nodeId);
    if (node) {
      dispatch(openDetailsDrawer(node));
    }
  };

  return (
    <NodeContentStyled onClick={handleOpenDrawer}>
      <Stack direction="row" flexWrap="wrap">
        {data.seniority && (
          <Label label={data.seniority} sx={{ margin: '0 0.5rem 0.5rem 0' }} />
        )}

        {data.platform?.map((platform) => (
          <Label
            key={platform}
            label={platform}
            sx={{
              margin: '0 0.5rem 0.5rem 0',
              color: palette.text.primary,
              background: palette.grey[100],
            }}
          />
        ))}
      </Stack>

      <Typography variant="body2">{data.title}</Typography>
    </NodeContentStyled>
  );
}
