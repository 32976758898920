import { User } from 'store/interfaces';

export function concatUserPosition(
  position: string = '',
  seniority: string | null = '',
) {
  return [position, seniority].filter((s) => !!s).join(', ');
}

export function getUserPosition(user: User) {
  const lastRecord = user.workRecords?.slice(-1)[0];
  const position = lastRecord?.position.name;
  const seniority = lastRecord?.seniority;
  return concatUserPosition(position, seniority ?? '');
}
