import { Suspense, lazy } from 'react';
import PageLoader from 'components/PageLoader';
import { AppRouteComponentProps } from 'components/AppRoutes';

const CareerRoadmapComponent = lazy(() => import('./CareerRoadmap.component'));

export default function CareerRoadmap(props: AppRouteComponentProps) {
  return (
    <Suspense fallback={<PageLoader open />}>
      <CareerRoadmapComponent {...props} />
    </Suspense>
  );
}
