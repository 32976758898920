import { useWatch } from 'react-hook-form';
import {
  FeedbackRequestFormValues,
  RequestGoalFormValues,
} from 'pages/FeedbackRequests/interfaces';
import { ScorecardExternalSubgoalRO } from 'store/interfaces';

export default function useGoalCommentValidation(
  index: number,
  subgoals: ScorecardExternalSubgoalRO[],
) {
  const formSubgoals = (useWatch<FeedbackRequestFormValues>({
    name: `goalHeaders.${index}.subgoals`,
  }) || []) as RequestGoalFormValues['subgoals'];

  const formResult = subgoals.reduce(
    (acc, _, idx) => {
      const currentValue =
        formSubgoals[idx]?.assessmentScaleId ||
        formSubgoals[idx]?.comment ||
        null;

      if (!currentValue) {
        acc.allScalesSet = false;
      } else {
        acc.filled += 1;
      }
      return acc;
    },
    { allScalesSet: true, filled: 0 },
  );

  const hasAllStandardScaleSubgoals = subgoals.every(
    (s) => s.assessmentScaleGroup.alias === 'standard',
  );

  return {
    hasAllStandardScaleSubgoals,
    ...formResult,
  };
}
