import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import PageLoader from 'components/PageLoader';
import { AppRouteComponentProps } from 'components/AppRoutes';
import { useAppDispatch } from 'store';
import { careerMentorsGet, changeFilter, selectSlice } from 'store/scorecards';
import { storeName } from 'store/scorecards/department/config';
import { useSelector } from 'react-redux';
import { DepartmentFilters } from 'store/scorecards/department';
import useReviewCycleOptions from 'hooks/reviewCycles/useReviewCycleOptions';
import { selectOptionsForDepartmentOverviewPage } from 'store/options';
import { usePageAccessible } from 'hooks';

const DepartmentOverviewComponent = lazy(
  () => import('./DepartmentOverview.component'),
);

export default function DepartmentOverview(props: AppRouteComponentProps) {
  const dispatch = useAppDispatch();
  const { init } = useSelector(selectSlice(storeName));
  const [firstCycle] = useReviewCycleOptions();
  const { units, locations } = useSelector(
    selectOptionsForDepartmentOverviewPage,
  );
  const hasAccess = usePageAccessible(props);
  const [definingDefaultFilters, setDefiningDefaultFilters] = useState(true);

  const initialFilters = useMemo(() => {
    const result = {};
    const defaultUnit = units.find(
      ({ name }) => name.toLowerCase() === 'eng web',
    );
    const defaultLocation = locations.find(
      ({ name }) => name.toLowerCase() === 'ukraine',
    );
    if (firstCycle) {
      Object.assign(result, { reviewCycleIds: firstCycle.id });
    }
    if (defaultLocation) {
      Object.assign(result, { countryIds: defaultLocation.id });
    }
    if (defaultUnit) {
      Object.assign(result, { unitIds: [defaultUnit.id] });
    }
    return result as DepartmentFilters;
  }, [firstCycle, locations, units]);

  useEffect(() => {
    if (init || !hasAccess) {
      setDefiningDefaultFilters(false);
    } else if (Object.keys(initialFilters).length === 3) {
      dispatch(
        changeFilter({
          store: storeName,
          data: initialFilters,
        }),
      );
      setDefiningDefaultFilters(false);
    }
  }, [dispatch, init, initialFilters, hasAccess]);

  useEffect(() => {
    if (!hasAccess) return;
    dispatch(careerMentorsGet({ store: storeName }));
  }, [dispatch, hasAccess]);

  return (
    <Suspense fallback={<PageLoader open />}>
      {definingDefaultFilters ? (
        <PageLoader open />
      ) : (
        <DepartmentOverviewComponent {...props} />
      )}
    </Suspense>
  );
}
