const downloadJson = (obj: unknown): void => {
  const json = JSON.stringify(obj, null, 2);

  const link = document.createElement('a');
  link.setAttribute('href', `data:text/json;charset=utf-8,${json}`);
  link.setAttribute('download', 'roadmap.json');

  link.click();
  link.remove();
};

export default downloadJson;
