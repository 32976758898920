import { scrollInsideContainer } from '../dom';

export const GOAL_NAVIGATION_TITLES_ID_NAME = 'goalsNavigationTitles';
export const GOALS_AND_SUBGOALS_ID_NAME = 'goalsAndSubgoals';
export const ACTIVE_GOAL_ID_NAME = 'activeGoal';

export function getNavigationGoalContainerIdName(activeGoalIndex: number) {
  return `goal_${activeGoalIndex}_container`;
}

export function getSubgoalDetailsIdName(
  goalIndex: number,
  subgoalIndex: number,
) {
  return `goals${goalIndex}subgoals${subgoalIndex}`;
}

export function scrollToActiveGoalInNavigation(activeGoalIndex: number) {
  scrollInsideContainer(
    `#${GOAL_NAVIGATION_TITLES_ID_NAME}`,
    `#${getNavigationGoalContainerIdName(activeGoalIndex)}`,
    24,
    0,
  );
}

export function scrollToActiveGoalDetails() {
  setTimeout(() => {
    const header = document.querySelector(
      `#${GOALS_AND_SUBGOALS_ID_NAME}`,
    ) as HTMLElement;
    window.scrollTo({
      top: header.offsetTop - 120,
      behavior: 'smooth',
    });
  }, 0);
}

export function scrollToActiveSubgoalDetails(
  goalIndex: number,
  subgoalIndex: number,
) {
  setTimeout(() => {
    const subgoalDetails = document.querySelector(
      `#${getSubgoalDetailsIdName(goalIndex, subgoalIndex)}`,
    ) as HTMLElement;
    window.scrollTo({
      top: subgoalDetails.offsetTop - 220,
      behavior: 'smooth',
    });
  }, 0);
}
