import { Suspense, lazy } from 'react';
import PageLoader from 'components/PageLoader';
import { AppRouteComponentProps } from 'components/AppRoutes';

const DirectReportsComponent = lazy(() => import('./DirectReports.component'));
const SubordinatesComponent = lazy(() => import('./Subordinates.component'));

export function DirectReports(props: AppRouteComponentProps) {
  return (
    <Suspense fallback={<PageLoader open />}>
      <DirectReportsComponent {...props} />
    </Suspense>
  );
}

export function Subordinates(props: AppRouteComponentProps) {
  return (
    <Suspense fallback={<PageLoader open />}>
      <SubordinatesComponent {...props} />
    </Suspense>
  );
}
