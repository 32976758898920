import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_CAREER_ROADMAP_SCHEMA } from 'constants/api';
import { EdgeNormalizer } from 'helpers';
import { storeName } from '../config';
import { RoadmapData } from '../interfaces';

export const roadmapData = createAsyncThunk<RoadmapData>(
  `${storeName}/get`,
  (_, thunkAPI) => {
    // TODO: temporary solution while we dont have proper env for development
    const isLocalhost = window.location.hostname === 'localhost';

    return api.axios
      .get<Record<'schema', RoadmapData>>(
        isLocalhost
          ? 'http://localhost:3000/roadmap.json'
          : API_CAREER_ROADMAP_SCHEMA,
      )
      .then((res) => {
        const { nodes, edges } = res.data.schema;
        return { nodes, edges: EdgeNormalizer.expand(edges) };
      })
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data));
  },
);
