import { Paper, styled } from '@mui/material';

export const NodeWrapperStyled = styled(Paper)(({ theme }) => ({
  width: '350px',
  padding: '0.4rem',
  border: `1px solid ${theme.palette.grey[400]}`,
  outline: 'none',

  '&:hover': {
    border: `1px solid ${theme.palette.grey[800]}`,
  },
  '&:focus': {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  '&:active': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export const RootNodeInner = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '5rem',
  padding: '0.2rem',
  borderRadius: '4px',
  border: 'none',
  background: theme.palette.primary.main,
  color: theme.palette.common.white,

  '> *': {
    textAlign: 'center',
  },
}));
