import { createSlice, Reducer } from '@reduxjs/toolkit';
import addCommonCases from 'store/addCommonCases';
import {
  addScorecardsCases,
  addCareerMentorOptionsCases,
} from 'store/scorecards/index';
import { cloneDeep } from 'lodash-es';
import {
  storeName,
  subordinateScorecardInitialState,
  initialFilters,
} from './config';

export * from './interfaces';

const subordinateScorecards = createSlice({
  name: storeName,
  initialState: cloneDeep(subordinateScorecardInitialState),
  reducers: {},
  extraReducers: (builder) => {
    addCareerMentorOptionsCases(builder, storeName);
    addCommonCases(builder, cloneDeep(subordinateScorecardInitialState));
    addScorecardsCases(builder, storeName, cloneDeep(initialFilters));
  },
});

export default subordinateScorecards.reducer as Reducer<
  typeof subordinateScorecardInitialState
>;
