import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.reviewCycles.title',
    defaultMessage: 'Review Cycles',
  },
  publishTemplate: {
    id: 'pages.reviewCycles.publishTemplate',
    defaultMessage: 'Publish Template',
  },
  publishTemplateTitle: {
    id: 'pages.reviewCycles.publishTemplateTitle',
    defaultMessage: 'Publish Template for {name}',
  },
  publishNoResult: {
    id: 'pages.reviewCycles.publishNoResult',
    defaultMessage:
      'There is no employee from the specified department in the selected location.',
  },
  noNewRecipients: {
    id: 'pages.reviewCycles.noNewRecipients',
    defaultMessage: 'No New Recipients selected',
  },
  searchToAddRecipient: {
    id: 'pages.reviewCycles.searchToAddRecipient',
    defaultMessage: 'Search to add a Recipient',
  },
  searchToAddOrRemovePeople: {
    id: 'pages.reviewCycles.searchToAddOrRemovePeople',
    defaultMessage: 'Search to add or remove people',
  },
  searchPeople: {
    id: 'pages.reviewCycles.searchPeople',
    defaultMessage: 'Search people',
  },
  singleTitle: {
    id: 'pages.reviewCycles.singleTitle',
    defaultMessage: 'Review Cycle',
  },
});
