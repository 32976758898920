import api from 'api';
import { Pagination, Changelog } from 'store/interfaces';
import { generateURL } from 'helpers';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { API_SCORECARD_AUDIT_LOGS } from 'constants/api';

type State = {
  items: Changelog[];
  pagination: Pagination;
};

const LOAD_LIMIT = 10;

export default function useChangelog(scorecardId: number) {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<State>({
    items: [],
    pagination: { limit: 0, page: 0, total: 0 },
  });

  const fetch = useCallback(() => {
    setLoading(true);
    return api.axios
      .get<State>(
        `${generateURL(API_SCORECARD_AUDIT_LOGS, {
          id: scorecardId,
        })}?${qs.stringify({ page: currentPage, limit: LOAD_LIMIT })}`,
      )
      .then(({ data: { items, pagination } }) => setData({ items, pagination }))
      .finally(() => setLoading(false));
  }, [currentPage, scorecardId]);

  useEffect(() => {
    fetch();
  }, [fetch, currentPage]);

  const fetchPage = useCallback(
    (page: number) => setCurrentPage(page),
    [setCurrentPage],
  );

  return {
    loading,
    fetchPage,
    data,
  };
}
