import { createSlice, isAnyOf, Reducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import { getCommonGoalsList } from './actions';
import { storeName, commonGoalsInitialState } from './config';
import addCommonCases from '../addCommonCases';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const commonGoals = createSlice({
  name: storeName,
  initialState: cloneDeep(commonGoalsInitialState),
  reducers: {},
  extraReducers: (builder) => {
    addCommonCases(builder, commonGoalsInitialState)
      .addCase(getCommonGoalsList.fulfilled, (state, action) => {
        state.items = action.payload.items;
      })
      .addCase(getCommonGoalsList.pending, (state) => {
        state.loading = true;
      });
    builder.addMatcher(
      isAnyOf(getCommonGoalsList.fulfilled, getCommonGoalsList.rejected),
      (state) => {
        state.loading = false;
      },
    );
  },
});

export default commonGoals.reducer as Reducer<typeof commonGoalsInitialState>;
