import { DialogTitle as MUIDialogTitle, IconButton } from '@mui/material';
import classNames from 'classnames';
import { CloseIcon } from 'components/Icons';
import { DialogTitleProps } from './interfaces';

export default function DialogTitle({
  onClose,
  children,
  className,
  ...other
}: DialogTitleProps) {
  return (
    <MUIDialogTitle
      {...other}
      sx={{ wordBreak: 'break-word' }}
      className={classNames(className, { withButton: onClose })}
    >
      {children}
      {onClose ? (
        <IconButton className="closeButton" onClick={onClose as () => void}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MUIDialogTitle>
  );
}
