import { memo } from 'react';
import { Box } from '@mui/system';
import palette from 'styles/theme/palette';
import { Stack, Typography } from '@mui/material';
import { type NodeProps, NodeResizer } from 'reactflow';
import { type NodeData } from 'store/roadmap';
import { isEqual } from 'lodash-es';
import { NodeGroupContent } from './NodeGroup.styled';
import NodeMenu from '../NodeMenu.component';
import Handlers from '../Handlers';

function NodeGroup({
  data,
  selected,
  sourcePosition,
  targetPosition,
  isConnectable,
}: NodeProps<NodeData>) {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
      }}
    >
      {isConnectable && (
        <NodeResizer
          minWidth={200}
          minHeight={100}
          isVisible={selected}
          color={palette.secondary.main}
        />
      )}

      <NodeGroupContent>
        <Stack direction="row" spacing={1} alignItems="center">
          {isConnectable && <NodeMenu />}

          <Typography sx={{ textAlign: 'left' }} variant="subtitle1">
            {data.title}
          </Typography>
        </Stack>
      </NodeGroupContent>

      <Handlers
        edges={data.edges}
        sourcePosition={sourcePosition}
        targetPosition={targetPosition}
        isConnectable={isConnectable}
      />
    </Box>
  );
}

NodeGroup.displayName = 'NodeGroup';

export default memo(
  NodeGroup,
  (prev, next) =>
    isEqual(prev.data, next.data) &&
    prev.targetPosition === next.targetPosition &&
    prev.sourcePosition === next.sourcePosition &&
    prev.selected === next.selected,
);
