import { createSlice, Reducer } from '@reduxjs/toolkit';
import addCommonCases from 'store/addCommonCases';
import addScorecardsCases from 'store/scorecards/addScorecardsCases';
import { cloneDeep } from 'lodash-es';
import {
  storeName,
  directReportScorecardInitialState,
  initialFilters,
} from './config';

export * from './interfaces';

const directReportScorecards = createSlice({
  name: storeName,
  initialState: cloneDeep(directReportScorecardInitialState),
  reducers: {},
  extraReducers: (builder) => {
    addCommonCases(builder, cloneDeep(directReportScorecardInitialState));
    addScorecardsCases(builder, storeName, cloneDeep(initialFilters));
  },
});

export default directReportScorecards.reducer as Reducer<
  typeof directReportScorecardInitialState
>;
