import {
  Chip as MUIChip,
  Checkbox as MUICheckbox,
  Box,
  styled,
} from '@mui/material';

export const List = styled('ul')(({ theme }) => ({
  '&[class*="SelectMenuSize"]': {
    padding: 0,
  },
  '& li': {
    ...theme.typography.body2,
    borderColor: theme.palette.grey.A400,
  },
  '&.SelectMenuSize-small li': {
    padding: '5px 10px',
  },
  '&.SelectMenuSize-medium li': {
    padding: '7px 10px',
  },
  '&.SelectMenuSize-large li': {
    padding: '9px 10px',
  },
}));

export const Checkbox = styled(MUICheckbox)({
  padding: 0,
  margin: '-2px 5px -2px 0',
  '.MuiSvgIcon-root': {
    fontSize: '24px',
  },
});

export const TooltipChip = styled(MUIChip)(({ theme }) => ({
  margin: '4px 4px 4px 0',
  height: '26px',
  fontSize: theme.typography.body2.fontSize,
  '.MuiChip-deleteIcon': {
    fontSize: 18,
    color: theme.palette.common.black,
  },
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  '.MuiAutocomplete-root': {
    minWidth: '200px',
    '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root':
      {
        paddingRight: '40px',
      },
  },
  '.MuiAutocomplete-root.Mui-focused': {
    '.tag-wrapper': {
      display: 'none',
    },
  },
  'label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input::placeholder':
    {
      opacity: '1!important',
      color: theme.palette.common.black,
    },
  '.MuiAutocomplete-input.MuiAutocomplete-input::placeholder': {
    opacity: 0,
    transition: 'none',
  },
  '.MuiOutlinedInput-root.MuiInputBase-adornedStart': {
    paddingLeft: 0,
  },
  '.MuiInputBase-input': {
    zIndex: 1,
  },
  '.MuiAutocomplete-endAdornment': {
    zIndex: 2,
  },
  '.MuiAutocomplete-clearIndicator': {
    marginRight: '10px',
  },
}));

export const TagWrapper = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: '8px 12px',
  position: 'absolute',
}));
