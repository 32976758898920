import { useTheme } from '@mui/material';
import { useSelector, batch } from 'react-redux';
import { logout, selectProfileInfo } from 'store/profile';
import { resizeImage } from 'helpers';
import SupportFeedbackButton from 'components/SupportFeedback';
import { useAccessibleRoutes } from 'hooks';
import { useAppDispatch } from 'store';
import { useCallback } from 'react';
import VersionsInfo from 'components/VersionsInfo';
import { selectHasDirtyForm } from 'store/uiState';
import { useIntl } from 'react-intl';
import { commonTexts } from 'i18n';
import { LogoIcon, LogoutIcon } from 'components/Icons';
import {
  Links,
  UserInfo,
  UserName,
  Avatar,
  LogoutButton,
  AppBar,
  Toolbar
} from './Header.styled';
import HeaderLink from './HeaderLink.component';

export default function Header() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { avatar, fullName } = useSelector(selectProfileInfo);
  const accessibleRoutes = useAccessibleRoutes();
  const hasDirtyForm = useSelector(selectHasDirtyForm);
  const { formatMessage } = useIntl();
  const onLogoutClick = useCallback(() => {
    const action = () => {
      batch(() => {
        dispatch(logout());
      });
    };

    if (!hasDirtyForm) {
      action();
      return;
    }

    // eslint-disable-next-line no-alert
    if (window.confirm(formatMessage(commonTexts.unsavedChangesPrompt))) {
      action();
    }
  }, [dispatch, formatMessage, hasDirtyForm]);

  return (
    <AppBar>
      <Toolbar data-testid="header-tool-bar">
        <LogoIcon
          logoColor={theme.palette.common.black}
          textColor={theme.palette.grey['700']}
        />
        <Links>
          {accessibleRoutes.map(({ path, title }) =>
            !title ? null : <HeaderLink key={path} path={path} title={title} />,
          )}
        </Links>
        <UserInfo>
          <VersionsInfo />
          <SupportFeedbackButton />
          <Avatar src={resizeImage(avatar)} />
          <UserName>{fullName}</UserName>
          <LogoutButton onClick={onLogoutClick} data-testid="logout-button">
            <LogoutIcon />
          </LogoutButton>
        </UserInfo>
      </Toolbar>
    </AppBar>
  );
}
