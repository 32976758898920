import { type NodeData } from 'store/roadmap';
import { memo } from 'react';
import { isEqual } from 'lodash-es';
import { Position } from 'reactflow';
import { HandlersStyled } from './Handlers.styled';

interface Props {
  edges?: NodeData['edges'];
  isConnectable: boolean;
  targetPosition?: Position;
  sourcePosition?: Position;
}

const defaultEdgesValue = {
  isTargetVisible: false,
  isSourceVisible: false,
};

function Handlers({
  isConnectable,
  edges = defaultEdgesValue,
  targetPosition = Position.Top,
  sourcePosition = Position.Bottom,
}: Props) {
  return (
    <>
      {edges.isTargetVisible && (
        <HandlersStyled
          type="target"
          position={targetPosition}
          isConnectable={isConnectable}
        />
      )}

      {edges.isSourceVisible && (
        <HandlersStyled
          type="source"
          position={sourcePosition}
          isConnectable={isConnectable}
        />
      )}
    </>
  );
}

Handlers.displayName = 'Handlers';

export default memo(Handlers, isEqual);
