import { useState, type MouseEvent, memo } from 'react';
import { useDispatch } from 'react-redux';
import {
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  IconButton,
} from '@mui/material';
import {
  EditIcon,
  MoreVertIcon,
  DeleteOutlineIcon,
  CopyToClipboardIcon,
} from 'components/Icons';
import PubSub from 'pubsub-js';
import { RoadmapEvents } from 'constants/roadmap';
import { openEditDrawer } from 'store/roadmap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNodeId, useNodes } from 'reactflow';

function NodeMenu() {
  const id = useNodeId();
  const nodes = useNodes();
  const dispatch = useDispatch();

  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleOpenEditDrawer = (): void => {
    const node = nodes.find((item) => item.id === id);
    if (node) {
      dispatch(openEditDrawer(node));
    }
    handleClose();
  };

  const handleDeleteNode = (): void => {
    PubSub.publish(RoadmapEvents.DELETE_NODE, id);
    handleClose();
  };

  const handleCopyNode = (): void => {
    PubSub.publish(RoadmapEvents.COPY_NODE, id);
    handleClose();
  };

  return (
    <>
      <IconButton
        size="small"
        onClick={handleOpen}
        aria-label={formatMessage({
          id: 'common.options',
          defaultMessage: 'Options',
        })}
      >
        <MoreVertIcon sx={{ fontSize: '1.1rem' }} />
      </IconButton>

      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={handleOpenEditDrawer}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage
              id="pages.roadmap.nodes.edit"
              defaultMessage="Edit item"
            />
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={handleCopyNode}>
          <ListItemIcon>
            <CopyToClipboardIcon />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage
              id="pages.roadmap.nodes.copy"
              defaultMessage="Copy"
            />
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={handleDeleteNode}>
          <ListItemIcon>
            <DeleteOutlineIcon />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage
              id="pages.common.delete"
              defaultMessage="Delete"
            />
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

NodeMenu.displayName = 'NodeMenu';

export default memo(NodeMenu);
