import palette from 'styles/theme/palette';

export default function GroupNodeIcon() {
  return (
    <svg viewBox="0 0 134 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="134" height="64" rx="9" fill="currentColor" />
      <rect
        x="4"
        y="4"
        rx="6"
        width="126"
        height="56"
        fill={palette.grey.A300}
        stroke={palette.common.white}
        strokeWidth="4"
      />
      <rect
        rx="1.5"
        x="56.7634"
        y="14.2808"
        width="28.0179"
        height="11.6027"
        fill={palette.grey.A500}
        stroke="currentColor"
      />
      <rect
        rx="1.5"
        x="27.7455"
        y="30.4452"
        width="28.0179"
        height="11.6027"
        fill={palette.grey.A500}
        stroke="currentColor"
      />
      <rect
        rx="1.5"
        x="71.2723"
        y="40.3082"
        width="28.0179"
        height="11.6027"
        fill={palette.grey.A500}
        stroke="currentColor"
      />
    </svg>
  );
}
