import { createContext, useContext, PropsWithChildren } from 'react';
import { ScorecardStores } from 'store/interfaces';

interface ScorecardContextValues {
  id: number;
  store: ScorecardStores;
}

const ScorecardContext = createContext<ScorecardContextValues>(
  {} as unknown as ScorecardContextValues,
);

export function useScorecardContext() {
  return useContext<ScorecardContextValues>(ScorecardContext);
}

export function ScorecardProvider({
  children,
  ...data
}: PropsWithChildren<ScorecardContextValues>) {
  return (
    <ScorecardContext.Provider value={data}>
      {children}
    </ScorecardContext.Provider>
  );
}
