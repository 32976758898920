import { ReactElement } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  FormControlLabel,
  Radio,
  RadioGroupProps,
  TextFieldProps,
} from '@mui/material';
import Select, { SelectOption, SelectProps } from 'components/Select';
import TextField from 'components/TextField';
import DatePicker, { DatePickerProps } from 'components/DatePicker';
import { getProperty } from 'helpers';
import { ControllerProps } from 'react-hook-form/dist/types/controller';
import Autocomplete from 'components/Autocomplete';
import {
  AutocompleteOption,
  AutocompleteProps,
} from 'components/Autocomplete/interfaces';
import GrowingTextField from 'components/ExpandableTextarea';
import Toggle from 'components/Toggle';
import MultiselectAutocomplete from 'components/MultiselectAutocomplete';
import RadioGroup from 'components/Radio/RadioGroup.component';
import { UnitedReactHookFormFieldProps } from './interfaces';
import { ToggleProps } from '../Toggle/interfaces';

export default function ReactHookFormField(
  props: UnitedReactHookFormFieldProps,
) {
  const {
    rules,
    name,
    type,
    label,
    placeholder = '',
    required,
    fieldProps,
    disabled,
    dataTestId,
  } = props;
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const renderField: ControllerProps['render'] = ({
    field: { ref, ...field },
  }): ReactElement => {
    const error = getProperty(errors, name);
    let errorMessage = error?.message;
    if (error && !errorMessage) {
      errorMessage = error[Object.keys(error)[0]];
    }
    const baseProps = {
      size: 'large',
      ...fieldProps,
      ...field,
      label,
      key: name,
      placeholder,
      required: required || typeof rules?.required !== 'undefined',
      disabled,
      'data-testid': dataTestId,
    };
    const extendedProps = {
      ...baseProps,
      fullWidth: true,
      error: Boolean(error),
      helperText: errorMessage,
      inputRef: ref,
    };
    switch (type) {
      case 'select': {
        const { options, multiple, groupBy, displayEmpty, clearValue } = props;

        return (
          <Select
            {...(extendedProps as SelectProps<SelectOption, unknown>)}
            options={options}
            groupBy={groupBy}
            multiple={multiple}
            displayEmpty={displayEmpty}
            clearValue={clearValue}
          />
        );
      }
      case 'autocomplete': {
        const { options, multiple } = props;
        const { value, ...autocompleteProps } = extendedProps;

        return (
          <Autocomplete
            {...(autocompleteProps as Omit<
              AutocompleteProps<
                AutocompleteOption,
                boolean | undefined,
                boolean | undefined,
                boolean | undefined
              >,
              'options'
            >)}
            options={options}
            onChange={(e, newValue) => {
              if (Array.isArray(newValue)) {
                extendedProps.onChange(
                  newValue.map((multiSelectItem) =>
                    typeof multiSelectItem === 'string'
                      ? multiSelectItem
                      : multiSelectItem.id,
                  ),
                );
                return;
              }

              extendedProps.onChange(
                typeof newValue === 'string' ? newValue : newValue?.id,
              );
            }}
            value={
              multiple
                ? options.filter(({ id }) => value?.includes(id))
                : options.find(({ id }) => id === value) || null
            }
            multiple={multiple}
          />
        );
      }
      case 'multiselect-autocomplete': {
        const { options, customTagsMessage, removeSelection } = props;
        const { value, ...autocompleteProps } = extendedProps;

        return (
          <MultiselectAutocomplete
            {...(autocompleteProps as Omit<
              AutocompleteProps<AutocompleteOption, true, false, false>,
              'options'
            >)}
            options={options}
            selectedOptions={value}
            removeSelection={removeSelection}
            onChange={(e, newValue) => {
              if (Array.isArray(newValue)) {
                extendedProps.onChange(
                  newValue.map((multiSelectItem) =>
                    typeof multiSelectItem === 'string'
                      ? multiSelectItem
                      : multiSelectItem.id,
                  ),
                );
              }
            }}
            customTagsMessage={customTagsMessage}
            value={options.filter(({ id }) => value?.includes(id))}
          />
        );
      }
      case 'datepicker':
        return <DatePicker {...(extendedProps as DatePickerProps)} />;
      case 'growing-text': {
        const { maxLength, selectOnFocus } = props;
        const { inputProps = {} } = fieldProps || {};
        if (maxLength) {
          Object.assign(inputProps, { maxLength });
        }
        return (
          <GrowingTextField
            {...(extendedProps as TextFieldProps)}
            inputProps={inputProps}
            type="text"
            multiline
            selectOnFocus={selectOnFocus}
          />
        );
      }
      case 'toggle': {
        const { value } = extendedProps;
        return (
          <Toggle
            inputRef={ref}
            checked={value}
            {...(baseProps as ToggleProps)}
          />
        );
      }
      case 'radio': {
        const { options, labelPlacement = 'top' } = props;
        return (
          <RadioGroup {...fieldProps} {...(baseProps as RadioGroupProps)}>
            {options.map((option) => (
              <FormControlLabel
                key={option.id}
                value={option.id}
                control={<Radio />}
                disabled={disabled}
                label={option.label || option.value}
                labelPlacement={labelPlacement}
              />
            ))}
          </RadioGroup>
        );
      }
      default: {
        const { maxLength, selectOnFocus } = props;
        const { inputProps = {} } = fieldProps || {};
        if (maxLength) {
          Object.assign(inputProps, { maxLength });
        }
        return (
          <TextField
            {...(extendedProps as TextFieldProps)}
            inputProps={inputProps}
            type={type === 'password' ? type : 'text'}
            multiline={type === 'textarea'}
            selectOnFocus={selectOnFocus}
          />
        );
      }
    }
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={renderField}
    />
  );
}
