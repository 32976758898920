import { createSlice, Reducer } from '@reduxjs/toolkit';
import { storeName, optionsInitialState } from './config';
import { updateOptions, updateAssessmentScales } from './actions';
import addCommonCases from '../addCommonCases';
import { updateCountries } from './actions/updateCountries';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const options = createSlice({
  name: storeName,
  initialState: optionsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    addCommonCases(builder, optionsInitialState)
      .addCase(updateOptions.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
      })
      .addCase(updateAssessmentScales.fulfilled, (state, action) => {
        Object.assign(state, { assessmentScales: action.payload });
      })
      .addCase(updateCountries.fulfilled, (state, action) => {
        Object.assign(state, { countries: action.payload });
      });
  },
});

export default options.reducer as Reducer<typeof optionsInitialState>;
